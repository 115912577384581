import clsx from "clsx";

import styles from "./Hero.module.scss";

interface HeroProps {
  className?: string;
  children?: React.ReactNode;
  verticallyCenter?: boolean;
}

function Hero({
  className,
  children,
  verticallyCenter,
  ...props
}: HeroProps): JSX.Element {
  return (
    <div
      className={clsx(
        className,
        styles.Hero,
        "text-reversed",
        verticallyCenter && styles["Hero-verticallyCenter"]
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export default Hero;
