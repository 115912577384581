import { useCallback } from "react";

import { CONSEQUENCE_MAX_LENGTH } from "constants/consequences";

import {
  Option,
  Consequence,
  User,
  ConsequenceType,
  EvaluationType,
  Evaluation,
} from "schema";

import { deepClone } from "utils/deepClone";
import { generateId } from "utils/generateId";

import { useSetUser } from "./UserContext";

export function useAddConsequence() {
  const setUser = useSetUser();

  return useCallback(
    (
      optionId: Option["id"],
      consequenceType: ConsequenceType,
      newText: Consequence["text"]
    ) => {
      if (newText.length === 0 || newText.length > CONSEQUENCE_MAX_LENGTH)
        throw new Error(
          `Text (${newText}) must be between 1 and ${CONSEQUENCE_MAX_LENGTH} ` +
            "characters"
        );
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.options[optionId])
          throw new Error(`Option (${optionId}) not found`);

        const newUser = deepClone(oldUser);

        const id = generateId();

        const newConsequence: Consequence = {
          id,
          text: newText,
          impact: 0,
          probability: 0,
        };

        newUser.consequences[id] = newConsequence;
        newUser.options[optionId][consequenceType].push(id);

        return newUser;
      });
    },
    [setUser]
  );
}

export function useEditConsequence() {
  const setUser = useSetUser();

  return useCallback(
    (consequenceId: Consequence["id"], newText: Consequence["text"]) => {
      if (newText.length === 0 || newText.length > CONSEQUENCE_MAX_LENGTH)
        throw new Error(
          `Text (${newText}) must be between 1 and ${CONSEQUENCE_MAX_LENGTH} ` +
            "characters"
        );
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.consequences[consequenceId])
          throw new Error(`Consequence (${consequenceId}) not found`);

        const newUser = deepClone(oldUser);

        newUser.consequences[consequenceId].text = newText;

        return newUser;
      });
    },
    [setUser]
  );
}

export function useSetConsequencesOrder() {
  const setUser = useSetUser();

  return useCallback(
    (
      optionId: Option["id"],
      consequenceType: ConsequenceType,
      newOrder: Option["upsides"] | Option["downsides"]
    ) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.options[optionId])
          throw new Error(`Option (${optionId}) not found`);

        const newUser = deepClone(oldUser);

        newUser.options[optionId][consequenceType] = newOrder;

        return newUser;
      });
    },
    [setUser]
  );
}

export function useDeleteConsequence() {
  const setUser = useSetUser();

  return useCallback(
    (
      consequenceId: Consequence["id"],
      consequenceType: ConsequenceType,
      optionId: Option["id"]
    ) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.consequences[consequenceId])
          throw new Error(`Consequence (${consequenceId}) not found`);

        const newUser = deepClone(oldUser);

        delete newUser.consequences[consequenceId];

        const option = newUser.options[optionId];
        option[consequenceType] = option[consequenceType].filter(
          (id: string) => id !== consequenceId
        );

        return newUser;
      });
    },
    [setUser]
  );
}

export function useEvaluateConsequence() {
  const setUser = useSetUser();

  return useCallback(
    (
      consequenceId: Consequence["id"],
      evaluationType: EvaluationType,
      newValue: Evaluation
    ) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.consequences[consequenceId])
          throw new Error(`Consequence (${consequenceId}) not found`);

        const newUser = deepClone(oldUser);

        newUser.consequences[consequenceId][evaluationType] = newValue;

        return newUser;
      });
    },
    [setUser]
  );
}
