import clsx from "clsx";

import styles from "./Container.module.scss";

interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
  id?: string;
}

function Container({
  className,
  children,
  ...props
}: ContainerProps): JSX.Element {
  return (
    <div className={clsx(className, styles.Container)} {...props}>
      {children}
    </div>
  );
}

export default Container;
