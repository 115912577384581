declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

const DEBUG_ANALYTICS = "debugAnalytics";
const DEBUG_STYLES = [
  "background: #48c4e4; color: #0b131d; font-weight: 600;",
  "background: #0b131d; color: #48c4e4; font-weight: 600;",
  "font-weight: 600",
];

function analyticsDebug(name: string, text: string, args: (string | object)[]) {
  if (
    process.env.NODE_ENV === "development" ||
    localStorage.getItem(DEBUG_ANALYTICS)
  ) {
    // Filter out falsey/empty arguments to avoid unnecessarily polluting the log
    const argsToShow = args.filter((arg) => {
      return typeof arg === "string" ? arg : Object.keys(arg).length;
    });
    console.log(
      `%c Analytics %c ${name} %c ${text}${argsToShow.length ? "\n" : ""}`,
      ...DEBUG_STYLES,
      ...argsToShow
    );
  }
}

(window as any).toggleAnalyticsDebugger = function (): void {
  if (localStorage.getItem(DEBUG_ANALYTICS)) {
    localStorage.removeItem(DEBUG_ANALYTICS);
    console.log("%c Analytics %c Debugger %c Disabled", ...DEBUG_STYLES);
  } else {
    localStorage.setItem(DEBUG_ANALYTICS, "true");
    console.log("%c Analytics %c Debugger %c Enabled", ...DEBUG_STYLES);
  }
};

function checkAnalytics() {
  if (!analytics) {
    console.warn("No analytics detected");
  }
  return !!analytics;
}

/**
 * A wrapper for the page function
 */
function page(pathname: string) {
  if (!checkAnalytics()) return;

  analytics.page();
  analyticsDebug("Page", pathname, []);
}

/**
 * A wrapper for the track event function
 * @param  {string} name Event name
 * @param  {any} properties Event properties
 */
function track(name: string, properties: any) {
  if (!checkAnalytics()) return;

  window.analytics.track(name, properties);
  analyticsDebug("Event", name, [properties as object]);
}

/**
 * A wrapper for the indentify function
 * @param  {string} id User id
 * @param  {any} traits Event traits
 */
function identify(id: string, traits: any) {
  if (!checkAnalytics()) return;

  window.analytics.identify(id, traits);
  analyticsDebug("Identify", id, [traits as object]);
}

/**
 * A wrapper for the reset function
 */
function reset() {
  if (!checkAnalytics()) return;

  window.analytics.reset();
  analyticsDebug("Reset", "", []);
}

const AnalyticsService = {
  page,
  track,
  identify,
  reset,
};

export default AnalyticsService;
