import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import routes from "constants/routes";
import { OOC_EMR, PRODUCT_NAME } from "constants/app";

import { useCreateUser, useUser } from "contexts/UserContext/UserContext";

import Button, { ButtonSize } from "components/Button/Button";
import Card from "components/Card/Card";
import Container from "components/Container/Container";
import Heading, { HeadingLevel } from "components/Heading/Heading";
import Hero from "components/Hero/Hero";

import styles from "./Intro.module.scss";

function Intro(): JSX.Element {
  const history = useHistory();

  const { user } = useUser();
  const createUser = useCreateUser();

  const handleSetupUser = useCallback(() => {
    if (!user) {
      createUser();
    }

    history.push(routes.OUTCOMES_URL);
  }, [user, createUser, history]);

  return (
    <main>
      <Hero>
        <Container>
          <Heading className={styles.HeroHeading} level={HeadingLevel.H1}>
            {PRODUCT_NAME} <strong className="nowrap">{OOC_EMR}</strong>
          </Heading>
          <p className={styles.HeroStrapline}>
            6&nbsp;Steps to Powerful &amp; Effective&nbsp;Decisions
          </p>
          <p>
            At any moment, the decision you make can change the course of your
            life—or your business—forever. Follow the steps below to access Tony
            Robbins’ proven system for making strong decisions that move you
            quickly toward the results you want&nbsp;most.
          </p>
        </Container>
      </Hero>

      <Card className="text">
        <p>
          Tough decisions exist everywhere in our lives: What car should I buy?
          What product should we make? Should we hire person a or person b?
          Should I leave my current relationship? Where should I invest
          my&nbsp;money?
        </p>
        <p>
          Without an effective system for making decisions, however, it’s easy
          to procrastinate and avoid making them, or make rash decisions that
          lead to a host of other&nbsp;problems.
        </p>
        <p>
          In order to move our lives and our businesses forward, we must develop
          the muscle of making decisions quickly and easily and following
          through on them. Different actions produce different results, and all
          actions begin with a&nbsp;decision.
        </p>
        <p>
          Powerful decisions build companies, bring people together, grow
          economies, solve problems, and move us forward as a&nbsp;society.
        </p>
        <p>It’s the ultimate power we all&nbsp;possess.</p>
        <p>
          Whether it’s a simple decision in your personal life, or a more
          complex decision that has the potential to greatly impact your
          business,{" "}
          <strong>
            use this powerful <span className="nowrap">{OOC_EMR}</span>{" "}
            decision-making model to gain clarity, make effective evaluations,
            increase productivity, foster collaboration, and help you resolve
            the best path to move forward.
          </strong>{" "}
          This is also an incredibly powerful process to implement with
          your&nbsp;team!
        </p>

        <p>
          Remember, it’s in your moments of decision that your destiny is
          shaped.
        </p>

        <div className={styles.Buttons}>
          <Button size={ButtonSize.LARGE} onClick={handleSetupUser} arrow block>
            {user ? "Continue" : "Get Started"}
          </Button>
        </div>
      </Card>
    </main>
  );
}

export default Intro;
