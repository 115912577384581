import { useLayoutEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * @param  {HTMLElement} el The element to scroll to
 * @param  {boolean} forceScroll An element will be scrolled to only if its top
 * is above the viewport unless this boolean is true
 */
export function scrollToElement(el: HTMLElement, forceScroll: boolean) {
  const { y } = el.getBoundingClientRect();
  // To avoid unnecessarily scrolling down, a check is made to be sure that the
  // card is stuck at the top of the viewport before scrolling the user
  if (y < 0 || forceScroll) {
    window.scrollTo(0, y + window.scrollY);
  }
}

function useSetInitialScroll() {
  const history = useHistory();
  const { hash, pathname } = useLocation();

  const firstRenderRef = useRef(false);

  // useLayoutEffect is necessary to avoid the browser recalculating their
  // previous scroll position if they are refreshing the page
  useLayoutEffect(() => {
    if (firstRenderRef.current) {
      return;
    }

    firstRenderRef.current = true;

    if (hash) {
      const hashEl = document.getElementById(hash.replace("#", ""));
      history.replace(pathname);
      if (hashEl) {
        scrollToElement(hashEl, true);
        return;
      }
    }

    window.scrollTo(0, 0);
  }, [history, hash, pathname]);
}

export default useSetInitialScroll;
