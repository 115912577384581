/**
 * This function patches sticky focus issues by forcibly scrolling to an element
 * that is partially obscured by the header or footer of a card
 * @param  {HTMLElement} el the focused element to scroll to
 * @param  {HTMLElement|null} cardEl the card element
 * @param  {HTMLElement|null} headerEl optional header element
 * @param  {HTMLElement|null} footerEl optional footer element
 * @returns void
 */
export function patchStickyFocus(
  focusedEl: HTMLElement,
  cardEl: HTMLElement,
  headerEl: HTMLElement | null,
  footerEl: HTMLElement | null
): void {
  // Nothing needs to happen if there is no sticky header or footer
  if (!headerEl && !footerEl) return;

  const parentStyle = window.getComputedStyle(cardEl);

  // The majority of these events need to be handled after the DOM has rendered,
  // so this little setTimeout forces it to happen after that has been done
  setTimeout(() => {
    const { top, height } = focusedEl.getBoundingClientRect();

    const windowY = window.scrollY;

    if (headerEl) {
      const headerBottom = headerEl.getBoundingClientRect().bottom;
      const scrollMarginTop = parseInt(
        parentStyle.getPropertyValue("--card-scroll-margin-top")
      );

      if (headerBottom >= top) {
        window.scrollTo(0, windowY + top - scrollMarginTop);
        // A check isn't made for the footer if the header requires scrolling
        // so that in an instance where both bounds are obscured, only the
        // header is calculated and honoured
        return;
      }
    }

    if (footerEl) {
      const footerTop = footerEl.getBoundingClientRect().top;
      const scrollMarginBottom = parseInt(
        parentStyle.getPropertyValue("--card-scroll-margin-bottom")
      );

      if (footerTop <= top + height) {
        // A card can either be full height (thus sticking top and bottom) or
        // taller. To calculate the position we need to scroll to we need the
        // position that it is stuck at, so we get either the height of the card
        // or the height of the viewport, whichever is shorter
        const parentOrViewportHeight = Math.min(
          cardEl.clientHeight,
          window.innerHeight
        );

        const newY =
          windowY + top - parentOrViewportHeight + height + scrollMarginBottom;

        window.scrollTo(0, newY);
        return;
      }
    }
  });
}
