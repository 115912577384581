import { ChangeEvent, useCallback, useState } from "react";
import clsx from "clsx";

import Button, { ButtonColor } from "components/Button/Button";
import Fieldset, { FieldsetLabelStyle } from "components/Fieldset/Fieldset";

import styles from "./EditingForm.module.scss";

interface EditingFormProps {
  className?: string;
  label: string;
  buttonLabel?: string;
  maxLength: number;
  onEdit: Function;
  onDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  initialValue?: string;
}

function EditingForm({
  className,
  maxLength,
  onEdit,
  onDelete,
  label = "Item",
  placeholder = `Enter text for ${label}`,
  initialValue = "",
}: EditingFormProps): JSX.Element {
  const [value, setValue] = useState(initialValue);

  const handleEdit = useCallback(
    (e) => {
      e.preventDefault();
      onEdit(value.trim());
    },
    [value, onEdit]
  );

  return (
    <form className={clsx(className, styles.Form)} onSubmit={handleEdit}>
      <Fieldset
        className={styles.Fieldset}
        label={label}
        labelStyle={FieldsetLabelStyle.HIDDEN}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus
      />
      <Button
        className={styles.Save}
        color={ButtonColor.REVERSED}
        disabled={!value || value.length > maxLength}
        type="submit"
        aria-label={value === initialValue ? "Cancel" : `Save ${label}`}
        title={value === initialValue ? "Cancel" : `Save ${label}`}
      >
        {value === initialValue ? "Cancel" : "Save"}
      </Button>
      {onDelete && (
        <Button
          color={ButtonColor.NEGATIVE}
          onClick={onDelete}
          type="button"
          aria-label={`Delete ${label}`}
          title={`Delete ${label}`}
        >
          Delete
        </Button>
      )}
    </form>
  );
}

export default EditingForm;
