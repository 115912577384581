import { Link } from "react-router-dom";

import routes from "constants/routes";

import Container from "components/Container/Container";

import { ReactComponent as TrLogo } from "./tr-logo.svg";

import styles from "./Header.module.scss";

function Header(): JSX.Element {
  return (
    <nav className={styles.Header}>
      <Container className={styles.Container}>
        <Link className={styles.Link} to={routes.INTRO_URL}>
          <span className="sr-only">Tony Robbins</span>
          <TrLogo className={styles.Logo} role="presentation" />
        </Link>
      </Container>
    </nav>
  );
}

export default Header;
