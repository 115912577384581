import Button, {
  ButtonSize,
  ButtonColor,
  LinkElementButtonProps,
} from "components/Button/Button";
import Heading, { HeadingLevel } from "components/Heading/Heading";

interface SharedItemHeadingProps {
  children?: React.ReactNode;
  number: number;
  label: string;
}

interface ItemHeadingWithButtonProps {
  onEditClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  to?: undefined;
}

interface ItemHeadingWithLinkProps {
  onEditClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  to: string;
}

type ItemHeadingProps = SharedItemHeadingProps &
  (ItemHeadingWithButtonProps | ItemHeadingWithLinkProps);

function ItemHeading({
  children,
  number,
  label,
  onEditClick,
  to,
}: ItemHeadingProps): JSX.Element {
  let buttonProps = {};

  if (to) {
    buttonProps = {
      to,
      onClick: onEditClick,
    } as LinkElementButtonProps;
  } else {
    buttonProps = {
      onClick: onEditClick,
    };
  }

  return (
    <>
      <Heading
        level={HeadingLevel.H4}
        marginTop={false}
        button={
          <Button
            {...buttonProps}
            color={ButtonColor.REVERSED}
            size={ButtonSize.SMALL}
            aria-label={`Edit ${label}`}
          >
            Edit
          </Button>
        }
      >
        {label} <span className="tabular-nums">{number}</span>
      </Heading>
      <Heading level={HeadingLevel.H2} style={{ margin: 0 }}>
        {children}
      </Heading>
    </>
  );
}

export default ItemHeading;
