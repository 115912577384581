import clsx from "clsx";

import Button, {
  ButtonColor,
  ButtonElementButtonProps,
} from "components/Button/Button";
import StateIndicator, {
  State,
} from "components/StateIndicator/StateIndicator";

import styles from "./ToggleButton.module.scss";

interface ToggleButtonProps {
  checked: boolean;
  uncheckedLabel: React.ReactNode;
  checkedLabel?: React.ReactNode;
}

function ToggleButton({
  className,
  checked,
  uncheckedLabel,
  checkedLabel = uncheckedLabel,
  ...props
}: ToggleButtonProps & ButtonElementButtonProps): JSX.Element {
  return (
    <Button
      className={clsx(
        className,
        styles.Toggle,
        checked && styles[`Toggle-checked`]
      )}
      color={checked ? ButtonColor.COMPLETE : ButtonColor.INACTIVE}
      {...props}
    >
      <span className={styles.Children}>
        <span
          className={clsx(
            styles.Label,
            styles["Label-unchecked"],
            uncheckedLabel === checkedLabel && styles["Label-matched"]
          )}
          aria-hidden={checked}
        >
          {uncheckedLabel}
        </span>
        <span className={styles.Label} aria-hidden={!checked}>
          {checkedLabel}
        </span>
        <StateIndicator
          className={styles.StateIndicator}
          state={checked ? State.COMPLETED : State.ACTIVE}
        />
      </span>
    </Button>
  );
}

export default ToggleButton;
