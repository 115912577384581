import { useCallback, useMemo, useRef } from "react";
import { generatePath, useHistory, useLocation } from "react-router";
import clsx from "clsx";

import { Consequence, Option, Outcome } from "schema";

import routes from "constants/routes";
import { OptionLabel, OptionsAnalyticsEvent } from "constants/options";
import { OutcomesLabel, OUTCOMES_REASON_LABEL } from "constants/outcomes";
import { ConsequenceLabels } from "constants/consequences";
import { MitigationsLabel } from "constants/mitigations";

import { formatLongText } from "utils/formatLongText";

import AnalyticsService from "services/AnalyticsService";

import { usePatchUser, useUser } from "contexts/UserContext/UserContext";

import { CardHeader } from "components/Card/Card";
import Heading, { HeadingLevel } from "components/Heading/Heading";
import ItemHeading from "components/Item/ItemHeading";
import ToggleButton from "components/ToggleButton/ToggleButton";
import List, { ListType } from "components/List/List";

import { EDIT_OPTION_ID } from "../Option/Option";
import { MitigationConsequencesGrid } from "../Option/Mitigation";
import { WrapperCard } from "../Wrapper/Wrapper";

import styles from "./ResolveOption.module.scss";

const SelectButtonLabel = {
  UNCHECKED: "Select as Best Option",
  CHECKED: "Selected",
};

interface ScoreBarProps {
  percent: number;
}

function ScoreBar({ percent }: ScoreBarProps): JSX.Element {
  return (
    <div
      className={styles.ScoreBar}
      style={
        { "--percent": percent } as {
          "--percent": number;
        } & React.CSSProperties
      }
      aria-hidden="true"
    />
  );
}

interface ResolveOptionProps {
  option: Option;
  index: number;
}

function ResolveOption({ option, index }: ResolveOptionProps): JSX.Element {
  const { id: optionId, linkedOutcomes, reason, mitigations, text } = option;

  const cardRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const { pathname } = useLocation();

  const { user, outcomes } = useUser();
  const patchUser = usePatchUser();

  const { upsides, downsides, percent } = useMemo(() => {
    if (!user) {
      return {
        upsides: [],
        downsides: [],
        percent: 0,
      };
    }

    function computeConsequences(
      consequenceIds: Option["upsides"] | Option["downsides"]
    ): [Consequence[], number] {
      const consequences = [];
      let total = 0;

      for (const id of consequenceIds) {
        const consequence = user?.consequences[id];
        if (consequence) {
          consequences.push(consequence);
          total += consequence.probability * consequence.impact;
        }
      }

      return [consequences, total];
    }

    const [upsides, positive] = computeConsequences(option.upsides);
    const [downsides, negative] = computeConsequences(option.downsides);

    const total = positive + negative;
    const percent = total === 0 ? 0 : Math.round((positive / total) * 100);

    return {
      upsides,
      downsides,
      percent,
    };
  }, [user, option]);

  const chosenOptionId = user?.chosenOptionId;

  const optionNumber = index + 1;

  const editUrl =
    generatePath(routes.OPTION_URL, { index: optionNumber }) +
    `#${EDIT_OPTION_ID}`;

  const handleGoToEdit = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      history.push(editUrl);
      AnalyticsService.track(OptionsAnalyticsEvent.EDIT_OPTION_URL, {
        number: optionNumber,
        location: pathname,
      });
    },
    [history, optionNumber, pathname, editUrl]
  );

  const handleSelectedOption = useCallback(() => {
    if (!patchUser) return;

    const newChosenOptionId =
      chosenOptionId === optionId ? undefined : optionId;

    patchUser({
      chosenOptionId: newChosenOptionId,
    });
  }, [patchUser, optionId, chosenOptionId]);

  const showOutcomes = !!linkedOutcomes.length || reason;
  const showUpsides = !!upsides.length;
  const showDownsides = !!downsides.length || !!mitigations.length;

  return (
    <WrapperCard
      ref={cardRef}
      header={
        <CardHeader className={styles.Header} ref={headerRef}>
          <ScoreBar percent={percent} />
          <ItemHeading
            label={OptionLabel.heading}
            number={optionNumber}
            onEditClick={handleGoToEdit}
            to={editUrl}
          >
            <span>{text}</span>
          </ItemHeading>
          <ToggleButton
            className={styles["Toggle-header"]}
            checked={optionId === chosenOptionId}
            onClick={handleSelectedOption}
            uncheckedLabel={SelectButtonLabel.UNCHECKED}
            checkedLabel={SelectButtonLabel.CHECKED}
          />
        </CardHeader>
      }
      headerRef={headerRef}
    >
      {showOutcomes && (
        <>
          <hr />
          <Heading level={HeadingLevel.H3}>
            {OutcomesLabel.headingPlural}
          </Heading>

          {!!linkedOutcomes.length && (
            <List type={ListType.ORDERED} tag={ListType.UNORDERED}>
              {outcomes?.map((outcome: Outcome, outcomeIndex) => {
                if (!linkedOutcomes.includes(outcome.id)) return null;

                return (
                  <li key={outcome.id} data-ol-marker={outcomeIndex + 1}>
                    {outcome.text}
                  </li>
                );
              })}
            </List>
          )}

          {reason && (
            <>
              <Heading level={HeadingLevel.H4}>{OUTCOMES_REASON_LABEL}</Heading>
              <p className="pre-wrap">{formatLongText(reason)}</p>
            </>
          )}
        </>
      )}

      {showUpsides && (
        <>
          <hr />
          <Heading level={HeadingLevel.H3}>
            {ConsequenceLabels.upsides.headingPlural}
          </Heading>
          <List type={ListType.ORDERED}>
            {upsides.map(({ id, text }: any) => (
              <li key={id}>{text}</li>
            ))}
          </List>
        </>
      )}

      {showDownsides && (
        <>
          <hr />
          <Heading level={HeadingLevel.H3}>
            {ConsequenceLabels.downsides.headingPlural}
          </Heading>

          {!!downsides.length && (
            <>
              <List type={ListType.ORDERED}>
                {downsides.map(({ id, text }: any) => (
                  <li key={id}>{text}</li>
                ))}
              </List>
            </>
          )}

          {!!mitigations.length && (
            <Heading level={HeadingLevel.H4}>
              {MitigationsLabel.headingPlural}
            </Heading>
          )}

          {mitigations.map((mitigationId: any, mitigationIndex) => {
            const mitigation: any = user?.mitigations[mitigationId];
            return (
              <details
                key={mitigationId}
                className={clsx(
                  styles.Mitigation,
                  mitigationIndex === 0 && styles["Mitigation-first"]
                )}
              >
                <summary>{mitigation.text}</summary>
                <MitigationConsequencesGrid
                  className={styles.MitigationConsequences}
                  upsides={mitigation.upsides}
                  downsides={mitigation.downsides}
                />
              </details>
            );
          })}
        </>
      )}

      {!showOutcomes && !showUpsides && !showDownsides && (
        <p className={styles.Empty}>
          No information entered for this&nbsp;{OptionLabel.text}
        </p>
      )}

      <ToggleButton
        className={styles["Toggle-footer"]}
        checked={optionId === chosenOptionId}
        onClick={handleSelectedOption}
        uncheckedLabel={SelectButtonLabel.UNCHECKED}
        checkedLabel={SelectButtonLabel.CHECKED}
        block
      />
    </WrapperCard>
  );
}

export default ResolveOption;
