// It is assumed that this will only ever increase. This should not be set to 0
export const APP_SCHEMA_VERSION = 2;

export enum Step {
  INTRO = "intro",
  OUTCOMES = "outcomes",
  CREATE_OPTIONS = "create_options",
  OPTION = "option",
  RESOLVE = "resolve",
}

export enum OptionStep {
  CONSEQUENCES = "consequences",
  EVALUATE = "evaluate",
  MITIGATE = "mitigate",
}

export enum ConsequenceType {
  UPSIDES = "upsides",
  DOWNSIDES = "downsides",
}

export interface Outcome {
  id: string;
  text: string;
}

export type Evaluation = number;

export enum EvaluationType {
  PROBABILITY = "probability",
  IMPACT = "impact",
}

export interface Consequence {
  id: string;
  text: string;
  [EvaluationType.IMPACT]: Evaluation;
  [EvaluationType.PROBABILITY]: Evaluation;
}

export enum MitigationConsequenceType {
  UPSIDES = "upsides",
  DOWNSIDES = "downsides",
}

export type MitigationConsequence = string;

export interface Mitigation {
  id: string;
  text: string;
  [MitigationConsequenceType.UPSIDES]?: MitigationConsequence;
  [MitigationConsequenceType.DOWNSIDES]?: MitigationConsequence;
}

export interface Option {
  id: string;
  text: string;
  reason: string;
  linkedOutcomes: Outcome["id"][];
  mitigations: Mitigation["id"][];
  [ConsequenceType.UPSIDES]: string[];
  [ConsequenceType.DOWNSIDES]: string[];
  [OptionStep.CONSEQUENCES]: boolean;
  [OptionStep.EVALUATE]: boolean;
  [OptionStep.MITIGATE]: boolean;
}

export interface User {
  id: string;
  schemaVersion: number;
  decisionId: string;
  step: Step;
  optionStep: OptionStep;
  outcomesOrder: string[];
  outcomes: {
    [id: string]: Outcome;
  };
  outcomesReason?: string;
  optionsOrder: string[];
  options: {
    [id: string]: Option;
  };
  consequences: {
    [id: string]: Consequence;
  };
  mitigations: {
    [id: string]: Mitigation;
  };
  chosenOptionId?: string;
  commitmentReason?: string;
  helperTooltips: {
    [id: string]: boolean;
  };
}
