import { ChangeEvent, useCallback, useRef, useState } from "react";
import clsx from "clsx";

import Button, { ButtonColor } from "components/Button/Button";
import Fieldset, { FieldsetLabelStyle } from "components/Fieldset/Fieldset";

import styles from "./CreationForm.module.scss";

interface CreationFormProps {
  className?: string;
  label: string;
  labelStyle?: FieldsetLabelStyle;
  placeholder?: string;
  buttonLabel?: string;
  maxLength: number;
  onAdd: (value: string) => void;
  marginTop?: boolean;
}

function CreationForm({
  className,
  label,
  labelStyle = FieldsetLabelStyle.DEFAULT,
  placeholder,
  buttonLabel = "Add",
  maxLength,
  onAdd,
  marginTop = true,
}: CreationFormProps): JSX.Element {
  const [value, setValue] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();
      onAdd(value.trim());
      setValue("");

      // This hack is required to force the focus event to reoccur after an item
      // is created so that the card code can check that it is still in view.
      // See Card.tsx for more information.
      inputRef.current?.blur();
      inputRef.current?.focus();
    },
    [onAdd, value]
  );

  return (
    <form
      className={clsx(
        className,
        styles.Form,
        marginTop && styles["Form-withMarginTop"],
        labelStyle === FieldsetLabelStyle.DEFAULT && styles["Form-withLabel"]
      )}
      onSubmit={handleAdd}
    >
      <Fieldset
        ref={inputRef}
        className={styles.Fieldset}
        label={label}
        labelStyle={labelStyle}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
      />
      <div className={styles.Buttons}>
        <Button
          color={ButtonColor.REVERSED}
          disabled={!value || value.length > maxLength}
          type="submit"
        >
          {buttonLabel}
        </Button>
      </div>
    </form>
  );
}

export default CreationForm;
