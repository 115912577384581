import clsx from "clsx";

import styles from "./Heading.module.scss";

export enum HeadingLevel {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
}

interface HeadingProps {
  className?: string;
  children?: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  level?: HeadingLevel;
  marginTop?: boolean;
  button?: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
}

function Heading({
  className,
  children,
  level = HeadingLevel.H2,
  tag: HeadingTag = level,
  marginTop = true,
  button,
  ...props
}: HeadingProps): JSX.Element {
  return (
    <HeadingTag
      className={clsx(
        className,
        styles[`Heading-${level}`],
        !marginTop && styles["Heading-noMarginTop"],
        button && styles["Heading-withButton"]
      )}
      {...props}
    >
      {button ? <span>{children}</span> : children}
      {button}
    </HeadingTag>
  );
}

export default Heading;
