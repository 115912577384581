import { useCallback } from "react";

import { User, Outcome } from "schema";

import {
  MAX_OUTCOMES,
  OUTCOMES_REASON_MAX_LENGTH,
  OUTCOME_MAX_LENGTH,
} from "constants/outcomes";

import { deepClone } from "utils/deepClone";
import { generateId } from "utils/generateId";

import { useSetUser, usePatchUser } from "./UserContext";

export function useSetOutcomesReason() {
  const patchUser = usePatchUser();

  return useCallback(
    (newReason: User["outcomesReason"]) => {
      if (newReason && newReason.length > OUTCOMES_REASON_MAX_LENGTH)
        throw new Error(
          `Reason (${newReason}) must not be more than ` +
            `${OUTCOMES_REASON_MAX_LENGTH} characters`
        );

      if (!patchUser) return;

      patchUser({
        outcomesReason: newReason,
      });
    },
    [patchUser]
  );
}

export function useAddOutcome() {
  const setUser = useSetUser();

  return useCallback(
    (newText: Outcome["text"]) => {
      if (newText.length === 0 || newText.length > OUTCOME_MAX_LENGTH)
        throw new Error(
          `Text (${newText}) must be between 1 and ${OUTCOME_MAX_LENGTH} ` +
            "characters"
        );

      if (!setUser) return;

      setUser((oldUser: User): User => {
        const outcomesCount = oldUser.outcomesOrder.length;
        if (outcomesCount >= MAX_OUTCOMES)
          throw new Error(
            `Maximum outcomes (${outcomesCount}) exceeds limit of ` +
              MAX_OUTCOMES
          );

        const newUser = deepClone(oldUser);

        const id = generateId();

        const newOutcome: Outcome = {
          id,
          text: newText,
        };

        newUser.outcomes[id] = newOutcome;
        newUser.outcomesOrder.push(id);

        return newUser;
      });
    },
    [setUser]
  );
}

export function useSetOutcomesOrder() {
  const patchUser = usePatchUser();

  return useCallback(
    (newOrder: User["outcomesOrder"]) => {
      if (!patchUser) return;

      patchUser({
        outcomesOrder: newOrder,
      });
    },
    [patchUser]
  );
}

export function useEditOutcome() {
  const setUser = useSetUser();

  return useCallback(
    (outcomeId: Outcome["id"], newText: Outcome["text"]) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.outcomes[outcomeId])
          throw new Error(`Outcome (${outcomeId}) not found`);

        const newUser = deepClone(oldUser);

        newUser.outcomes[outcomeId].text = newText;

        return newUser;
      });
    },
    [setUser]
  );
}

export function useDeleteOutcome() {
  const setUser = useSetUser();

  return useCallback(
    (outcomeId: Outcome["id"]) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        const { options, outcomesOrder, optionsOrder, outcomes } = oldUser;
        if (!outcomes[outcomeId])
          throw new Error(`Outcome (${outcomeId}) not found`);

        const newUser = deepClone(oldUser);

        delete newUser.outcomes[outcomeId];
        newUser.outcomesOrder = outcomesOrder.filter((id) => id !== outcomeId);

        // Iterate through the linked outcomes of all options to ensure that
        // it gets removed from there as well
        optionsOrder.forEach((optionId) => {
          const { linkedOutcomes } = options[optionId];
          newUser.options[optionId].linkedOutcomes = linkedOutcomes.filter(
            (id) => id !== outcomeId
          );
        });

        return newUser;
      });
    },
    [setUser]
  );
}
