import { NBSP } from "./app";

export const MIN_OUTCOMES = 1;
export const MAX_OUTCOMES = 6;
export const OUTCOME_MAX_LENGTH = 75;

export const OUTCOMES_REASON_LABEL = "Why?";
export const OUTCOMES_REASON_MAX_LENGTH = 360;

export const OutcomesLabel = {
  heading: "Outcome",
  headingPlural: "Outcomes",
  text: "outcome",
  textPlural: "outcomes",
};

export const OutcomesAnalyticsEvent = {
  EDIT_URL: "User clicked 'Edit Outcomes'",
  CREATE: "User created an outcome",
  DELETE: "User deleted an outcome",
  EDIT: "User edited an outcome",
  REORDER: "User reordered their outcomes",
};

export const DELETE_OUTCOME_CONFIRMATION = `Are you sure you want to delete this${NBSP}outcome?`;
