import { forwardRef, useCallback } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import clsx from "clsx";

import { Outcome } from "schema";

import routes from "constants/routes";
import { OutcomesAnalyticsEvent } from "constants/outcomes";

import AnalyticsService from "services/AnalyticsService";

import { useUser } from "contexts/UserContext/UserContext";

import Button, { ButtonColor, ButtonSize } from "components/Button/Button";
import Card, { CardProps } from "components/Card/Card";
import Container from "components/Container/Container";
import Heading, { HeadingLevel } from "components/Heading/Heading";
import Hero from "components/Hero/Hero";
import List, { ListType } from "components/List/List";

import styles from "./Wrapper.module.scss";

const OUTCOMES_ID = "outcomes";

export const WrapperCard = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, ...props }, ref): JSX.Element => {
    return (
      <Card
        className={clsx(className, styles.ContainerCard)}
        ref={ref}
        {...props}
      >
        {children}
      </Card>
    );
  }
);

interface WrapperProps {
  children?: React.ReactNode;
  heading: string;
  copy?: React.ReactNode;
  stepNumber: number;
}

function Wrapper({
  children,
  heading,
  copy,
  stepNumber,
  ...props
}: WrapperProps): JSX.Element {
  const history = useHistory();
  const { pathname } = useLocation();

  const { outcomes } = useUser();

  const routeMatchesOutcomes = useRouteMatch(routes.OUTCOMES_URL)?.isExact;

  const editOutcomesUrl = `${routes.OUTCOMES_URL}#${OUTCOMES_ID}`;

  const handleGoToEdit = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      history.push(editOutcomesUrl);
      AnalyticsService.track(OutcomesAnalyticsEvent.EDIT_URL, {
        location: pathname,
      });
    },
    [history, pathname, editOutcomesUrl]
  );

  return (
    <main {...props}>
      <Hero>
        <Container className={styles.HeroContent} data-step-number={stepNumber}>
          <Heading level={HeadingLevel.H1} marginTop={false}>
            <span className={styles.HeroStepNumber}>{stepNumber}. </span>
            {heading}
          </Heading>
          {copy}
        </Container>
      </Hero>
      <Container className={styles.Container}>
        <Card
          className={clsx(
            styles.Outcomes,
            routeMatchesOutcomes && styles["Outcomes-outcomesRoute"]
          )}
          reversed
          id={OUTCOMES_ID}
        >
          {!!outcomes?.length && (
            <div className={styles.OutcomesInner}>
              <Heading
                level={HeadingLevel.H4}
                marginTop={false}
                button={
                  !routeMatchesOutcomes && (
                    <Button
                      color={ButtonColor.REVERSED}
                      onClick={handleGoToEdit}
                      to={editOutcomesUrl}
                      size={ButtonSize.SMALL}
                    >
                      Edit
                    </Button>
                  )
                }
              >
                Outcomes
              </Heading>
              <List className={styles.OutcomesList} type={ListType.ORDERED}>
                {outcomes.map((outcome: Outcome) => (
                  <li key={outcome.id}>{outcome.text}</li>
                ))}
              </List>
            </div>
          )}
        </Card>
        {children}
      </Container>
    </main>
  );
}

export default Wrapper;
