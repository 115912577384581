import { useCallback, useRef } from "react";
import { Redirect } from "react-router-dom";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { Outcome, Step, User } from "schema";

import routes from "constants/routes";
import {
  MAX_OUTCOMES,
  MIN_OUTCOMES,
  OutcomesAnalyticsEvent,
  OUTCOMES_REASON_LABEL,
  OUTCOMES_REASON_MAX_LENGTH,
  OUTCOME_MAX_LENGTH,
} from "constants/outcomes";
import { OptionLabel } from "constants/options";

import useSetInitialScroll from "hooks/useSetInitialScroll";

import AnalyticsService from "services/AnalyticsService";

import { useRedirectStep, useUser } from "contexts/UserContext/UserContext";
import {
  useAddOutcome,
  useSetOutcomesReason,
  useSetOutcomesOrder,
} from "contexts/UserContext/useOutcome";

import Button, { ButtonSize } from "components/Button/Button";
import CreationForm from "components/CreationForm/CreationForm";
import Fieldset from "components/Fieldset/Fieldset";

import OutcomeItem from "./OutcomeItem";
import Wrapper, { WrapperCard } from "../Wrapper/Wrapper";
import WrapperCardFooter from "../Wrapper/WrapperCardFooter";

import styles from "./Outcomes.module.scss";

function Outcomes(): JSX.Element {
  useSetInitialScroll();

  const cardRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const { user, outcomes } = useUser();

  const outcomesOrder = user?.outcomesOrder;

  const addOutcome = useAddOutcome();
  const setOutcomesOrder = useSetOutcomesOrder();
  const setOutcomesReason = useSetOutcomesReason();
  const redirectUserStep = useRedirectStep(Step.OUTCOMES);

  const handleAddOutcome = useCallback(
    (newText: Outcome["text"]) => {
      addOutcome(newText);
      AnalyticsService.track(OutcomesAnalyticsEvent.CREATE, {
        length: newText.length,
      });
    },
    [addOutcome]
  );

  const handleReasonChange = useCallback(
    (e) => {
      setOutcomesReason(e.target.value);
    },
    [setOutcomesReason]
  );

  const handleReorder = useCallback(
    (result: DropResult) => {
      if (!outcomesOrder) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination?.index;

      // Ignore the rorder if there is no destination or if there is no change
      if (destinationIndex === undefined || sourceIndex === destinationIndex) {
        return;
      }

      const newOrder: User["outcomesOrder"] = [...outcomesOrder];
      const [idToMove] = newOrder.splice(sourceIndex, 1);
      newOrder.splice(destinationIndex, 0, idToMove);

      setOutcomesOrder(newOrder);

      AnalyticsService.track(OutcomesAnalyticsEvent.REORDER, {});
    },
    [outcomesOrder, setOutcomesOrder]
  );

  if (redirectUserStep) {
    return <Redirect to={redirectUserStep} />;
  }

  return (
    <Wrapper
      heading="Get Clear on Your Outcomes"
      stepNumber={1}
      copy={
        <>
          <p>
            Remember, all decision-making is values clarification. Important
            decisions often become complex because you’re trying to achieve
            multiple outcomes with the same&nbsp;decision.
          </p>
          <p>
            Brainstorm the most important outcomes that you need to ideally
            achieve with this decision. What’s the result you’re&nbsp;after?
          </p>
          <p>
            Then, for each outcome, capture why you want to achieve it. What are
            the reasons why this outcome is important to&nbsp;you?
          </p>
          <p>
            Once you’ve captured all of your outcomes, re-order your outcomes
            (drag and drop) so that they are listed in their order of importance
            to&nbsp;you.
          </p>
        </>
      }
    >
      <WrapperCard
        ref={cardRef}
        footer={
          <WrapperCardFooter
            ref={footerRef}
            helperText={
              <span>Add at least {MIN_OUTCOMES} Outcome to&nbsp;progress</span>
            }
            button={
              <Button
                size={ButtonSize.LARGE}
                arrow
                block
                to={routes.CREATE_OPTIONS_URL}
              >
                {OptionLabel.headingPlural}
              </Button>
            }
            disabled={!outcomes || outcomes.length < MIN_OUTCOMES}
          />
        }
        footerRef={footerRef}
      >
        <DragDropContext onDragEnd={handleReorder}>
          <Droppable droppableId="outcomes">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {outcomes?.map((outcome: Outcome, index: number) => (
                  <Draggable
                    key={outcome.id}
                    draggableId={outcome.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <OutcomeItem
                        ref={provided.innerRef}
                        id={outcome.id}
                        text={outcome.text}
                        number={index + 1}
                        dragging={snapshot.isDragging}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {outcomes && outcomes.length < MAX_OUTCOMES && (
          <CreationForm
            onAdd={handleAddOutcome}
            label={`Desired Outcome ${outcomes.length + 1}`}
            placeholder="What do you want to achieve?"
            buttonLabel="Add Outcome"
            maxLength={OUTCOME_MAX_LENGTH}
            marginTop={outcomes.length > 0}
          />
        )}

        <Fieldset
          className={styles.Why}
          type="textarea"
          label={OUTCOMES_REASON_LABEL}
          value={user?.outcomesReason || ""}
          onChange={handleReasonChange}
          maxLength={OUTCOMES_REASON_MAX_LENGTH}
          placeholder="Why do you want to achieve this?"
          forceMaxLength
        />
      </WrapperCard>
    </Wrapper>
  );
}

export default Outcomes;
