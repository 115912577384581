/**
 * Performs a deep clone on an object for simple usecases (does not preserve
 * functions etc). It uses any to allow any shape of object
 * @param  {any} object – must be JS type object
 * @returns cloned object
 */
export function deepClone(object: any): any {
  if (typeof object !== "object" && object !== null)
    throw new Error(`object (${object}) must be an object.`);

  return JSON.parse(JSON.stringify(object));
}
