import isEmail from "validator/es/lib/isEmail";

import { Consequence, Mitigation, Option, Outcome, User } from "schema";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

/**
 * A function to convert the user object into something more consumable for
 * storage
 * @param  {User} user
 */
function normalizeDataForStorage(user: User) {
  const { outcomes, options, chosenOptionId, commitmentReason } = user;

  function normalizeOutcome(outcomeId: Outcome["id"]) {
    return outcomes[outcomeId].text;
  }

  function normalizeConsequence(consequenceId: Consequence["id"]) {
    const { text, impact, probability } = user.consequences[consequenceId];

    return {
      text,
      impact,
      probability,
    };
  }

  function normalizeMitigation(mitigationId: Mitigation["id"]) {
    const { text, upsides, downsides } = user.mitigations[mitigationId];

    return {
      text,
      upsides,
      downsides,
    };
  }

  function normalizeOption(optionId: Option["id"]) {
    const { text, linkedOutcomes, upsides, downsides, reason, mitigations } =
      options[optionId];

    return {
      text,
      reason,
      linkedOutcomes: linkedOutcomes.map((id) => normalizeOutcome(id)),
      upsides: upsides.map((id) => normalizeConsequence(id)),
      downsides: downsides.map((id) => normalizeConsequence(id)),
      mitigations: mitigations.map((id) => normalizeMitigation(id)),
    };
  }

  return {
    outcomes: user.outcomesOrder.map((id) => normalizeOutcome(id)),
    options: user.optionsOrder.map((id) => normalizeOption(id)),
    chosenOption: chosenOptionId ? normalizeOption(chosenOptionId) : null,
    commitmentReason,
  };
}

/**
 * Stores normalized user data to the database
 * @param  {string} email
 * @param  {User} user
 */
async function storeData(email: string, user: User) {
  const token = await window.grecaptcha.execute(
    process.env.REACT_APP_GRECAPTCHA_KEY,
    { action: "submit" }
  );

  const apiUrl = process.env.REACT_APP_DATA_API_URL;

  if (!apiUrl) throw new Error("Data API Url environment variable missing");

  if (!isEmail(email)) throw new Error("Invalid email");

  const body = {
    userId: user.id,
    decisionId: user.decisionId,
    email,
    data: normalizeDataForStorage(user),
    token,
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const json = await response.json();

    if (response.status >= 400) {
      const { message } = json;

      if (message.includes("Unsuccessful captcha verification"))
        throw new Error(
          `Unsuccessful captcha verification with a score of ${json.score}`
        );

      throw new Error(message);
    }

    return json;
  } catch (error) {
    throw error;
  }
}

const DataService = {
  storeData,
};

export default DataService;
