import clsx from "clsx";

import { ReactComponent as IconCheck } from "assets/icons/16-check.svg";

import styles from "./StateIndicator.module.scss";

export enum State {
  PAST = "past",
  FUTURE = "future",
  ACTIVE = "active",
  COMPLETED = "completed",
}

interface StateIndicatorProps {
  className?: string;
  state: State;
}

function StateIndicator({
  className,
  state,
  ...props
}: StateIndicatorProps): JSX.Element {
  return (
    <span
      className={clsx(
        className,
        styles.StateIndicator,
        styles[`StateIndicator-${state}`]
      )}
      {...props}
    >
      <IconCheck role="presentation" />
    </span>
  );
}

export default StateIndicator;
