import { useCallback } from "react";

import { User } from "schema";

import { deepClone } from "utils/deepClone";

import { useSetUser, useUser } from "./UserContext";

export function useHelperTooltipDismissed(tooltipId: string): boolean {
  const { user } = useUser();

  return !!user?.helperTooltips[tooltipId];
}

export function useDismissHelperTooltip() {
  const setUser = useSetUser();

  return useCallback(
    (tooltipId: string) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        const newUser: User = deepClone(oldUser);

        newUser.helperTooltips[tooltipId] = true;

        return newUser;
      });
    },
    [setUser]
  );
}
