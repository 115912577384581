const INTRO_URL = "/";
const OUTCOMES_URL = "/outcomes";
const CREATE_OPTIONS_URL = "/options/create";
const OPTION_URL = "/options/:index";
const EVALUATE_URL = "/evaluate";
const MITIGATE_URL = "/mitigate/:index";
const RESOLVE_URL = "/resolve";

export const externalUrls = {
  CONTACT: "https://www.tonyrobbins.com/contact-us/",
  PRIVACY_POLICY: "https://www.tonyrobbins.com/privacy-policy",
  TERMS_OF_USE: "https://www.tonyrobbins.com/terms-of-use/",
};

const routes = {
  INTRO_URL,
  OUTCOMES_URL,
  CREATE_OPTIONS_URL,
  OPTION_URL,
  EVALUATE_URL,
  MITIGATE_URL,
  RESOLVE_URL,
};

export default routes;
