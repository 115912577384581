import { forwardRef, useCallback, useState } from "react";
import clsx from "clsx";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import {
  Option,
  OptionStep,
  Consequence,
  ConsequenceType,
  EvaluationType,
  Evaluation,
} from "schema";

import {
  ConsequenceLabels,
  EvaluationLabels,
  ConsequencesAnalyticsEvent,
  CONSEQUENCE_MAX_LENGTH,
  DELETE_CONSEQUENCE_CONFIRMATION,
} from "constants/consequences";
import { OptionLabel } from "constants/options";

import AnalyticsService from "services/AnalyticsService";

import { useUser } from "contexts/UserContext/UserContext";
import {
  useAddConsequence,
  useDeleteConsequence,
  useEditConsequence,
  useEvaluateConsequence,
  useSetConsequencesOrder,
} from "contexts/UserContext/useConsequence";

import EditingForm from "components/EditingForm/EditingForm";
import CreationForm from "components/CreationForm/CreationForm";
import { FieldsetLabelStyle } from "components/Fieldset/Fieldset";
import Heading, { HeadingLevel } from "components/Heading/Heading";
import HelperTooltip from "components/HelperTooltip/HelperTooltip";
import { DraggableItem, DraggableItemProps } from "components/Item/Item";
import Button, { ButtonColor, ButtonSize } from "components/Button/Button";

import { ReactComponent as IconArrowRight } from "assets/icons/16-arrow-right.svg";

import styles from "./Consequences.module.scss";

interface EvaluationSelectProps {
  value: Evaluation;
  onEvaluate: (value: Evaluation, type: EvaluationType) => void;
  type: EvaluationType;
  consequenceType?: ConsequenceType;
  showHelperTooltip?: boolean;
}

function EvaluationSelect({
  value,
  onEvaluate,
  type,
  consequenceType,
  showHelperTooltip,
}: EvaluationSelectProps): JSX.Element {
  const { optionStep } = useUser();

  const label = EvaluationLabels[type];

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onEvaluate(parseInt(e.target.value), type);
    },
    [onEvaluate, type]
  );

  return (
    <label className={styles.Evaluation}>
      <Heading
        className={styles.EvaluationLabel}
        level={HeadingLevel.H5}
        tag="span"
      >
        {label}
      </Heading>
      <div
        className={clsx(
          styles.EvaluationSelect,
          consequenceType && styles[`EvaluationSelect-${consequenceType}`]
        )}
        data-value={value}
      >
        {showHelperTooltip && (
          <HelperTooltip
            className={styles.EvaluationTooltip}
            tooltipId="evaluate"
            dismiss={value > 0 || optionStep !== OptionStep.EVALUATE}
          />
        )}
        <span className={styles.EvaluationValue}>{value}</span>
        <span className={styles.EvaluationArrows}>
          <IconArrowRight role="presentation" />
          <IconArrowRight role="presentation" />
        </span>
        <select value={value} onChange={handleChange} title={`Set ${label}`}>
          <option disabled>Select {label}…</option>
          {[...new Array(10)].map((_, index) => (
            <option value={index + 1} key={index}>
              {index + 1}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
}

interface ConsequencesItemProps {
  consequence: Consequence;
  consequenceType: ConsequenceType;
  optionStep: OptionStep;
  optionId: Option["id"];
  showHelperTooltip?: boolean;
}

type DraggableConsequenceItemProps = ConsequencesItemProps & DraggableItemProps;

const ConsequencesItem = forwardRef<
  HTMLDivElement,
  DraggableConsequenceItemProps
>(
  (
    {
      consequence,
      consequenceType,
      optionStep,
      showHelperTooltip,
      optionId,
      ...props
    },
    ref
  ): JSX.Element => {
    const [editing, setEditing] = useState(false);

    const { id, text, impact, probability } = consequence;

    const editConsequence = useEditConsequence();
    const deleteConsequence = useDeleteConsequence();
    const evaluateConsequence = useEvaluateConsequence();

    const canEvaluate = optionStep !== OptionStep.CONSEQUENCES;

    const handleEdit = useCallback(
      (newText: Consequence["text"]) => {
        const changed = newText !== text;
        if (changed) {
          editConsequence(id, newText);
        }
        setEditing(false);
        AnalyticsService.track(ConsequencesAnalyticsEvent.EDIT, {
          changed,
          type: consequenceType,
          optionStep,
        });
      },
      [id, text, editConsequence, consequenceType, optionStep]
    );

    const handleDelete = useCallback(() => {
      if (!window.confirm(DELETE_CONSEQUENCE_CONFIRMATION)) return;

      deleteConsequence(id, consequenceType, optionId);
      AnalyticsService.track(ConsequencesAnalyticsEvent.DELETE, {
        type: consequenceType,
        optionStep,
      });
    }, [id, deleteConsequence, optionId, consequenceType, optionStep]);

    const handleEvaluation = useCallback(
      (evaluation: Evaluation, evaluationType: EvaluationType) => {
        evaluateConsequence(id, evaluationType, evaluation);
        AnalyticsService.track(ConsequencesAnalyticsEvent.EVALUATE, {
          [evaluationType]: evaluation,
          type: consequenceType,
          optionStep,
        });
      },
      [id, evaluateConsequence, consequenceType, optionStep]
    );

    return (
      <DraggableItem
        ref={ref}
        className={clsx(!editing && styles["Consequence-notEditing"])}
        {...props}
      >
        {editing ? (
          <EditingForm
            maxLength={CONSEQUENCE_MAX_LENGTH}
            label="Edit Consequence"
            onEdit={handleEdit}
            onDelete={handleDelete}
            initialValue={text}
          />
        ) : (
          <div className={styles.ConsequenceWrapper}>
            <p className={styles.ConsequenceText}>{text}</p>

            {canEvaluate && (
              <div className={styles.ConsequenceEvaluations}>
                <EvaluationSelect
                  value={impact}
                  onEvaluate={handleEvaluation}
                  type={EvaluationType.IMPACT}
                  consequenceType={consequenceType}
                  showHelperTooltip={showHelperTooltip}
                />

                <EvaluationSelect
                  value={probability}
                  type={EvaluationType.PROBABILITY}
                  onEvaluate={handleEvaluation}
                />
              </div>
            )}

            <div className={styles.ConsequenceEdit}>
              <Button
                size={ButtonSize.SMALL}
                color={ButtonColor.REVERSED}
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            </div>
          </div>
        )}
      </DraggableItem>
    );
  }
);

ConsequencesItem.displayName = "ConsequencesItem";

interface ConsequencesProps {
  consequenceType: ConsequenceType;
  option: Option;
  optionNumber: number;
  showHelperTooltip?: boolean;
}

function Consequences({
  consequenceType,
  option,
  optionNumber,
  showHelperTooltip = false,
}: ConsequencesProps): JSX.Element | null {
  const { user, optionStep } = useUser();

  const consequencesOrder = option[consequenceType];
  const optionId = option.id;

  const addConsequence = useAddConsequence();
  const setConsequencesOrder = useSetConsequencesOrder();

  const handleReorder = useCallback(
    (result: DropResult) => {
      if (!consequencesOrder) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination?.index;

      // Ignore the rorder if there is no destination or if there is no change
      if (destinationIndex === undefined || sourceIndex === destinationIndex) {
        return;
      }

      const newOrder: Option["upsides"] | Option["downsides"] = [
        ...consequencesOrder,
      ];
      const [idToMove] = newOrder.splice(sourceIndex, 1);
      newOrder.splice(destinationIndex, 0, idToMove);

      setConsequencesOrder(optionId, consequenceType, newOrder);

      AnalyticsService.track(ConsequencesAnalyticsEvent.REORDER, {
        type: consequenceType,
        optionStep,
      });
    },
    [
      setConsequencesOrder,
      consequencesOrder,
      optionId,
      consequenceType,
      optionStep,
    ]
  );

  const handleAdd = useCallback(
    (newText) => {
      addConsequence(optionId, consequenceType, newText);
      AnalyticsService.track(ConsequencesAnalyticsEvent.CREATE, {
        length: newText.length,
        type: consequenceType,
        optionStep,
      });
    },
    [addConsequence, consequenceType, optionId, optionStep]
  );

  const { heading, headingPlural, text, textPlural } =
    ConsequenceLabels[consequenceType];

  const consequences: Consequence[] = user
    ? option[consequenceType].map((id) => user.consequences[id])
    : [];

  const helperLabelhidden =
    !consequences.length || optionStep === OptionStep.CONSEQUENCES;

  if (!optionStep) return null;

  return (
    <div className={styles.Consequences} key={optionId}>
      <Heading className={styles.Heading} level={HeadingLevel.H3}>
        {headingPlural}
        <HelperTooltip
          tooltipId={`assess-${consequenceType}`}
          dismiss={
            consequences.length > 0 || optionStep !== OptionStep.CONSEQUENCES
          }
        />
      </Heading>

      <div className={styles.Helper}>
        <p className={styles.HelperText}>
          What are the potential {textPlural} to this&nbsp;{OptionLabel.text}?
        </p>
        <Heading
          className={clsx(
            styles.HelperLabel,
            helperLabelhidden && styles["HelperLabel-hidden"]
          )}
          level={HeadingLevel.H5}
          tag="span"
          aria-hidden="true"
        >
          {EvaluationLabels[EvaluationType.IMPACT]}
        </Heading>
        <Heading
          className={clsx(
            styles.HelperLabel,
            helperLabelhidden && styles["HelperLabel-hidden"]
          )}
          level={HeadingLevel.H5}
          tag="span"
          aria-hidden="true"
        >
          {EvaluationLabels[EvaluationType.PROBABILITY]}
        </Heading>
      </div>

      <DragDropContext onDragEnd={handleReorder}>
        <Droppable droppableId={consequenceType}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={clsx(
                consequences.length > 0 && styles["DraggableList-withItems"]
              )}
              {...provided.droppableProps}
            >
              {consequences.map((consequence: Consequence, index: number) => (
                <Draggable
                  key={consequence.id}
                  draggableId={consequence.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <ConsequencesItem
                      ref={provided.innerRef}
                      dragging={snapshot.isDragging}
                      consequence={consequence}
                      consequenceType={consequenceType}
                      optionStep={optionStep}
                      optionId={optionId}
                      showHelperTooltip={showHelperTooltip && index === 0}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <CreationForm
        className={styles.CreationForm}
        onAdd={handleAdd}
        label={`Create New ${heading}`}
        labelStyle={FieldsetLabelStyle.HIDDEN}
        buttonLabel={`Add ${heading}`}
        maxLength={CONSEQUENCE_MAX_LENGTH}
        placeholder={`Add a ${text} for ${OptionLabel.heading} ${optionNumber}`}
      />
    </div>
  );
}

export default Consequences;
