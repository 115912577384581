import { useCallback } from "react";

import { Option, User, OptionStep, Outcome } from "schema";

import { MAX_OPTIONS, OPTION_MAX_LENGTH } from "constants/options";

import { deepClone } from "utils/deepClone";
import { generateId } from "utils/generateId";

import { usePatchUser, useSetUser } from "./UserContext";

export function useAddOption() {
  const setUser = useSetUser();

  return useCallback(
    (newText: Option["text"]) => {
      if (newText.length === 0 || newText.length > OPTION_MAX_LENGTH)
        throw new Error(
          `Text (${newText}) must be between 1 and ${OPTION_MAX_LENGTH} ` +
            "characters"
        );
      if (!setUser) return;

      setUser((oldUser: User): User => {
        const optionsCount = oldUser.optionsOrder.length;
        if (optionsCount >= MAX_OPTIONS)
          throw new Error(
            `Maximum outcomes (${optionsCount}) exceeds limit of ${MAX_OPTIONS}`
          );

        const newUser: User = deepClone(oldUser);

        const id = generateId();

        const newOption: Option = {
          id,
          text: newText,
          reason: "",
          upsides: [],
          linkedOutcomes: [],
          downsides: [],
          mitigations: [],
          consequences: false,
          evaluate: false,
          mitigate: false,
        };

        newUser.options[id] = newOption;
        newUser.optionsOrder.push(id);

        return newUser;
      });
    },
    [setUser]
  );
}

export function useEditOption() {
  const setUser = useSetUser();

  return useCallback(
    (optionId: Option["id"], newText: Option["text"]) => {
      if (newText.length === 0 || newText.length > OPTION_MAX_LENGTH)
        throw new Error(
          `Text (${newText}) must be between 1 and ${OPTION_MAX_LENGTH} ` +
            "characters"
        );
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.options[optionId])
          throw new Error(`Option (${optionId}) not found`);

        const newUser = deepClone(oldUser);

        newUser.options[optionId].text = newText;

        return newUser;
      });
    },
    [setUser]
  );
}

export function useSetOptionsOrder() {
  const patchUser = usePatchUser();

  return useCallback(
    (newOrder: User["optionsOrder"]) => {
      if (!patchUser) return;

      patchUser({
        optionsOrder: newOrder,
      });
    },
    [patchUser]
  );
}

export function useDeleteOption() {
  const setUser = useSetUser();

  return useCallback(
    (optionId: Option["id"]) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.options[optionId])
          throw new Error(`Option (${optionId}) not found`);

        const newUser: User = deepClone(oldUser);

        newUser.optionsOrder = oldUser.optionsOrder.filter(
          (id) => id !== optionId
        );
        delete newUser.options[optionId];

        // Ensures that if a user deletes an option that they have picked, the
        // PDF export is no longer possible
        if (oldUser.chosenOptionId === optionId) {
          delete newUser.chosenOptionId;
        }

        return newUser;
      });
    },
    [setUser]
  );
}

export function useLinkOutcome() {
  const setUser = useSetUser();

  return useCallback(
    (optionId: Option["id"], outcomeId: Outcome["id"], linked: boolean) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.options[optionId])
          throw new Error(`Option (${optionId}) not found`);
        if (!oldUser.outcomes[outcomeId])
          throw new Error(`Outcome (${outcomeId}) not found`);

        const newUser = deepClone(oldUser);

        if (linked) {
          if (!oldUser.options[optionId].linkedOutcomes.includes(outcomeId)) {
            newUser.options[optionId].linkedOutcomes.push(outcomeId);
          }
        } else {
          newUser.options[optionId].linkedOutcomes = oldUser.options[
            optionId
          ].linkedOutcomes.filter((id) => id !== outcomeId);
        }

        return newUser;
      });
    },
    [setUser]
  );
}

export function useToggleOptionState() {
  const setUser = useSetUser();

  return useCallback(
    (optionId: Option["id"], step: OptionStep) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.options[optionId])
          throw new Error(`Option (${optionId}) not found`);

        const newUser = deepClone(oldUser);

        newUser.options[optionId][step] = !newUser.options[optionId][step];

        return newUser;
      });
    },
    [setUser]
  );
}
