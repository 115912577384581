import { EvaluationType, ConsequenceType } from "schema";

import { NBSP } from "./app";

export const CONSEQUENCE_MAX_LENGTH = 100;

export const ConsequenceLabels = {
  [ConsequenceType.UPSIDES]: {
    heading: "Upside",
    headingPlural: "Upsides",
    textPlural: "upsides",
    text: "upside",
  },
  [ConsequenceType.DOWNSIDES]: {
    heading: "Downside",
    headingPlural: "Downsides",
    textPlural: "downsides",
    text: "downside",
  },
};

export const EvaluationLabels = {
  [EvaluationType.IMPACT]: "Impact",
  [EvaluationType.PROBABILITY]: "Probability",
};

export const ConsequencesAnalyticsEvent = {
  CREATE: "User created consequence",
  EDIT: "User edited consequence",
  DELETE: "User deleted consequence",
  REORDER: "User reordered their consequences",
  EVALUATE: "User evaluated consequence",
};

export const DELETE_CONSEQUENCE_CONFIRMATION = `Are you sure you want to delete this${NBSP}consequence?`;
