import { forwardRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { Option } from "schema";

import {
  DELETE_OPTION_CONFIRMATION,
  OptionLabel,
  OptionsAnalyticsEvent,
  OPTION_MAX_LENGTH,
} from "constants/options";

import AnalyticsService from "services/AnalyticsService";

import { useDeleteOption, useEditOption } from "contexts/UserContext/useOption";

import EditingForm from "components/EditingForm/EditingForm";
import { DraggableItem, DraggableItemProps } from "components/Item/Item";
import ItemHeading from "components/Item/ItemHeading";

interface CreateOptionsItemProps {
  id: Option["id"];
  text: Option["text"];
  number: number;
}

type DraggableCreateOptionsItemProps = CreateOptionsItemProps &
  DraggableItemProps;

const CreateOptionsItem = forwardRef<
  HTMLDivElement,
  DraggableCreateOptionsItemProps
>(({ id, text, number, ...props }, ref): JSX.Element => {
  const { pathname } = useLocation();

  const editOption = useEditOption();
  const deleteOption = useDeleteOption();

  const [editing, setEditing] = useState(false);

  const handleEdit = useCallback(
    (newText: Option["text"]) => {
      const changed = newText !== text;
      if (changed) {
        editOption(id, newText);
      }
      setEditing(false);

      AnalyticsService.track(OptionsAnalyticsEvent.EDIT, {
        changed,
        length: newText.length,
      });
    },
    [id, text, editOption]
  );

  const handleDelete = useCallback(() => {
    if (!window.confirm(DELETE_OPTION_CONFIRMATION)) return;

    deleteOption(id);
    AnalyticsService.track(OptionsAnalyticsEvent.DELETE, {
      location: pathname,
    });
  }, [id, deleteOption, pathname]);

  return (
    <DraggableItem ref={ref} {...props}>
      {editing ? (
        <EditingForm
          label={`${OptionLabel.heading} ${number}`}
          initialValue={text}
          onEdit={handleEdit}
          onDelete={handleDelete}
          maxLength={OPTION_MAX_LENGTH}
        />
      ) : (
        <div>
          <ItemHeading
            label={OptionLabel.heading}
            number={number}
            onEditClick={() => setEditing(true)}
          >
            {text}
          </ItemHeading>
        </div>
      )}
    </DraggableItem>
  );
});

CreateOptionsItem.displayName = "CreateOptionsItem";

export default CreateOptionsItem;
