import { forwardRef, Ref, useEffect } from "react";
import clsx from "clsx";

import { patchStickyFocus } from "./Card.utils";

import styles from "./Card.module.scss";

interface CardHeaderProps {
  className?: string;
  children?: React.ReactNode;
}

export const CardHeader = forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ className, children }, ref): JSX.Element => {
    return (
      <>
        <div className={clsx(styles.StickyEnd, styles["StickyEnd-header"])} />
        <div
          className={clsx(styles.StickySpacer, styles["StickySpacer-header"])}
        />
        <div
          ref={ref}
          className={clsx(
            className,
            styles.StickyElement,
            styles["StickyElement-header"]
          )}
        >
          {children}
        </div>
      </>
    );
  }
);

CardHeader.displayName = "CardHeader";

interface CardFooterProps {
  className?: string;
  children?: React.ReactNode;
}

export const CardFooter = forwardRef<HTMLDivElement, CardFooterProps>(
  ({ className, children }, ref): JSX.Element => {
    return (
      <>
        <div
          ref={ref}
          className={clsx(
            className,
            styles.StickyElement,
            styles["StickyElement-footer"]
          )}
        >
          {children}
        </div>
        <div
          className={clsx(styles.StickySpacer, styles["StickySpacer-footer"])}
        />
        <div className={clsx(styles.StickyEnd, styles["StickyEnd-footer"])} />
      </>
    );
  }
);

export interface CardProps {
  className?: string;
  children?: React.ReactNode;
  reversed?: Boolean;
  header?: React.ReactNode;
  headerRef?: Ref<HTMLDivElement>;
  footer?: React.ReactNode;
  footerRef?: Ref<HTMLDivElement>;
  id?: string;
}

const TAGNAMES_TO_FIX_FOCUS = ["INPUT", "TEXTAREA", "SELECT"];

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      children,
      reversed,
      header,
      headerRef,
      footer,
      footerRef,
      ...props
    },
    ref
  ): JSX.Element => {
    // scroll-margin-* doesn't honor input, textarea or select when scrolling
    // a user's active element into view, so this patches the functionality by
    // using refs for the card and the header/footer to do the heavy lifting in
    // one place rather than assign refs to every focusable element across the
    // app and handle it individually. It's the lesser of two evils, but there
    // seems to be little choice here.
    useEffect(() => {
      if (!ref) return;

      const refCurrent: HTMLElement | null =
        typeof ref === "object" ? ref.current : null;

      function handleFocus(e: FocusEvent) {
        const target = e.target as HTMLElement;

        // This patch is only needed for certain elements
        if (!TAGNAMES_TO_FIX_FOCUS.includes(target.tagName)) return;

        // forwardRef can return two types of ref, but as we're using the
        // .current approach in all instances, we make a check firstly to see
        // if it is an object, and then if the ref actually exists. The same
        // check is made for the headerEl and footerEl
        if (typeof ref !== "object" || !ref?.current) return;

        const headerEl = typeof headerRef === "object" && headerRef?.current;
        const footerEl = typeof footerRef === "object" && footerRef?.current;

        patchStickyFocus(
          target,
          ref.current,
          headerEl || null,
          footerEl || null
        );
      }

      refCurrent?.addEventListener("focusin", handleFocus);
      return () => {
        refCurrent?.removeEventListener("focusin", handleFocus);
      };
    }, [ref, headerRef, footerRef]);

    return (
      <div
        className={clsx(
          className,
          styles.Card,
          reversed && styles["Card-reversed"],
          header && styles["Card-withStickyHeader"],
          footer && styles["Card-withStickyFooter"]
        )}
        ref={ref}
        {...props}
      >
        {header}
        {children}
        {footer}
      </div>
    );
  }
);

Card.displayName = "Card";

export default Card;
