import { MitigationConsequenceType } from "schema";

import { NBSP } from "./app";

export const MAX_MITIGATIONS = 3;
export const MITIGATION_MAX_LENGTH = 100;

export const MITIGATION_CONSEQUENCE_MAX_LENGTH = 200;

export const MitigationsLabel = {
  heading: "Mitigation",
  headingPlural: "Mitigations",
  text: "mitigation",
  textPlural: "mitigations",
  verb: "mitigate",
};

export const MitigationConsequenceLabel = {
  [MitigationConsequenceType.UPSIDES]: {
    headingPlural: "Upsides",
    textPlural: "upsides",
    placeholder: "List the upsides of this mitigation",
  },
  [MitigationConsequenceType.DOWNSIDES]: {
    headingPlural: "Downsides",
    textPlural: "upsides",
    placeholder: "List the downsides of this mitigation",
  },
};

export const MitigationAnalyticsEvent = {
  CREATE: "User created a mitigation",
  DELETE: "User deleted a mitigation",
  EDIT: "User edited a mitigation",
};

export const DELETE_MITIGATION_CONFIRMATION = `Are you sure you want to delete this${NBSP}mitigation?`;
