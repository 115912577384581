import clsx from "clsx";

import { externalUrls } from "constants/routes";

import Container from "components/Container/Container";

import styles from "./Footer.module.scss";

function Footer(): JSX.Element {
  return (
    <footer className={clsx(styles.Footer, "text-reversed")}>
      <Container className={styles.Container}>
        <p>
          &copy; {new Date().getFullYear()} Robbins Research International, Inc.
          All rights reserved.
        </p>
        <ul>
          <li>
            <a href={externalUrls.CONTACT} target="_blank" rel="noreferrer">
              Contact us
            </a>
          </li>
          <li>
            <a
              href={externalUrls.PRIVACY_POLICY}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
      </Container>
    </footer>
  );
}

export default Footer;
