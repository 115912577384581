import { OptionStep } from "schema";

import { NBSP } from "./app";

export const MIN_OPTIONS = 3;
export const MAX_OPTIONS = 5;
export const OPTION_MAX_LENGTH = 75;

export const OptionLabel = {
  heading: "Option",
  headingPlural: "Options",
  text: "option",
  textPlural: "options",
};

export const OptionStepLabel = {
  [OptionStep.CONSEQUENCES]: {
    heading: "Assess Possible Consequences",
    breadcrumb: "Consequences",
    verb: "Assess",
    completedVerb: "Assessed",
  },
  [OptionStep.EVALUATE]: {
    heading: "Evaluate Your Options",
    breadcrumb: "Evaluate",
    verb: "Evaluate",
    completedVerb: "Evaluated",
  },
  [OptionStep.MITIGATE]: {
    heading: "Mitigate the Damage",
    breadcrumb: "Mitigate",
    verb: "Mitigate",
    completedVerb: "Mitigated",
  },
};

export const OptionProgressButtonLabel = {
  [OptionStep.CONSEQUENCES]: "Evaluate",
  [OptionStep.EVALUATE]: "Mitigate",
  [OptionStep.MITIGATE]: "Resolve",
};

export const OptionsAnalyticsEvent = {
  EDIT_OPTIONS_URL: "User clicked 'Edit Options'",
  EDIT_OPTION_URL: "User clicked 'Edit Option'",
  PROGRESS_STEP: "User progressed to the next option step",
  CREATE: "User created an option",
  DELETE: "User deleted an option",
  EDIT: "User edited an option",
  REORDER: "User reordered their options",
  TOGGLE_STATE: "User toggled option state",
  LINK_OUTCOME: "User linked outcome",
  UNLINK_OUTCOME: "User unlinked outcome",
  NAVIGATE: "User navigated to option",
};

export const DELETE_OPTION_CONFIRMATION = `Are you sure you want to delete this${NBSP}option?`;
