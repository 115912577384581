import { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import isEmail from "validator/es/lib/isEmail";
import clsx from "clsx";

import { Step } from "schema";

import { externalUrls } from "constants/routes";

import useSetInitialScroll from "hooks/useSetInitialScroll";

import AnalyticsService from "services/AnalyticsService";
import DataService from "services/DataService";

import { sleep } from "utils/sleep";

import { NBSP } from "constants/app";

import {
  usePatchUser,
  useRedirectStep,
  useStartNewDecision,
  useUser,
} from "contexts/UserContext/UserContext";

import Button, { ButtonColor, ButtonSize } from "components/Button/Button";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Fieldset, { FieldsetColor } from "components/Fieldset/Fieldset";

import ResolveOption from "./ResolveOption";
// import ResolveVillain from "./ResolveVillain";
import Wrapper, { WrapperCard } from "../Wrapper/Wrapper";

import styles from "./Resolve.module.scss";

export const COMMITMENT_REASON_LABEL =
  "I am committing to this option because…";

const NEW_DECISION_CONFIRMATION =
  "Are you sure you want to start a new decision. All progress with your " +
  `current decision will be${NBSP}lost.`;

function Resolve(): JSX.Element | null {
  useSetInitialScroll();

  const redirectUserStep = useRedirectStep(Step.RESOLVE);

  const { user, options } = useUser();
  const patchUser = usePatchUser();
  const startNewDecision = useStartNewDecision();

  const [email, setEmail] = useState("");
  const [emailDirty, setEmailDirty] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [exportError, setExportError] = useState("");

  const chosenOptionId = user?.chosenOptionId;

  useEffect(() => {
    if (chosenOptionId) {
      AnalyticsService.track("User selected best option", {});
    }
  }, [chosenOptionId]);

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!patchUser) return;

      patchUser({
        commitmentReason: e.target.value,
      });
      setExportError("");
    },
    [patchUser]
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      setExportError("");
    },
    []
  );

  const handleExportPdf = useCallback(
    async (e) => {
      e.preventDefault();

      if (exporting || !user || !email || !isEmail(email)) return;

      setExportError("");

      // As this is not integral to the user's experience, this is handled
      // asyncronously with errors logged via the analytics and a console.warn
      // so as not to disrupt the export of the PDF
      DataService.storeData(email, user)
        .then((response) => {
          AnalyticsService.track("Data store succeeded", {
            decisionId: response.decisionId,
            score: response.score,
          });
        })
        .catch((error) => {
          AnalyticsService.track("Data Store failed", {
            error: error.message || error.toString(),
          });
          console.warn("Data Store failed", error);
        });

      AnalyticsService.identify(user.id, { email });
      AnalyticsService.track("PDF Export started", {
        commitmentReasonLength: user.commitmentReason?.length || 0,
      });

      setExporting(true);

      // Delays the UI changes long enough to show the loading state and clear
      // the error so that a user doesn't get confused if nothing changes
      await sleep(500);

      // This is downloaded asyncronously to avoid lumping all of the Pdf
      // bundlers in with the page load when there is a chance some users won't
      // use it. It is wrapped in a Promise to allow for a timeout to throw an
      // error if it takes too long to download
      try {
        const { generatePdf } = await new Promise((resolve, reject) => {
          const timeout = setTimeout(() => {
            reject(new Error("PDF download timedout"));
          }, 15000);

          import("./ResolvePdf")
            .then((response) => {
              resolve(response);
              clearTimeout(timeout);
            })
            .catch((error) => {
              reject(error);
              clearTimeout(timeout);
            });
        });

        await generatePdf(user);
      } catch (error) {
        console.error(error);
        setExportError("Something went wrong, please try again.");

        setExporting(false);

        AnalyticsService.track("PDF Export failed", { error });

        return;
      }

      AnalyticsService.track("PDF Export completed", {});

      setExporting(false);
    },
    [user, email, exporting]
  );

  const handleStartNewDecision = useCallback(() => {
    if (!window.confirm(NEW_DECISION_CONFIRMATION)) return;

    startNewDecision();
    AnalyticsService.track("User started new decision", {});
  }, [startNewDecision]);

  if (redirectUserStep) {
    return <Redirect to={redirectUserStep} />;
  }

  if (!user) return null;

  return (
    <>
      <Wrapper
        heading="Resolve"
        stepNumber={6}
        copy={
          <>
            <p>
              It’s time for the big finish! Based on your refined list of
              options (including any additional strategies or options you came
              up with), select the option that provides the greatest certainty
              that you will meet your desired&nbsp;outcomes.
            </p>
            <p>
              This is your best option—and because you’ve looked at so many
              other possibilities and you evaluated each one in detail, you can
              have the confidence that this option represents your highest
              probability of&nbsp;success.
            </p>
            <p>
              Resolve that, no matter what happens, this option will give you a
              win. Even if your tough decision ends up not getting your core
              outcomes, you can still choose what that means to you. Instead of
              seeing it as a failure, you can choose to view it as a learning
              experience or a jumping off point to go in a different direction.
              Now, all that’s left is designing your plan for implementation and
              then taking massive&nbsp;action.
            </p>
          </>
        }
      >
        <div>
          {options?.map((option, index) => (
            <ResolveOption key={option.id} option={option} index={index} />
          ))}

          <WrapperCard className={clsx(styles.SubmissionCard, "text-reversed")}>
            <form onSubmit={handleExportPdf}>
              <Fieldset
                type="textarea"
                color={FieldsetColor.REVERSED}
                label={COMMITMENT_REASON_LABEL}
                placeholder={`Enter the reasons why this is the best solution for${NBSP}you…`}
                value={user?.commitmentReason || ""}
                onChange={handleReasonChange}
              />

              <Fieldset
                type="email"
                color={FieldsetColor.REVERSED}
                label="Email address"
                placeholder="Enter your email address"
                error={
                  emailDirty && !isEmail(email)
                    ? "Invalid email address"
                    : undefined
                }
                value={email}
                onChange={handleEmailChange}
                onBlur={() => setEmailDirty(true)}
                autoComplete="email"
              />
              <div className={styles.SubmissionTerms}>
                <p>
                  <small>
                    By entering your email address you agree to the{" "}
                    <a
                      href={externalUrls.TERMS_OF_USE}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Use
                    </a>
                    .
                  </small>
                  <small>
                    {/* This is required by Google in order to hide the
                        unstylable reCAPTCHA box in the corner. It is the lesser
                        of two evils. */}
                    This site is protected by reCAPTCHA and the Google{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://policies.google.com/terms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>{" "}
                    apply.
                  </small>
                </p>

                <div className={styles.SubmissionButtons}>
                  <Button
                    size={ButtonSize.LARGE}
                    color={ButtonColor.INACTIVE}
                    disabled={!chosenOptionId || !email || !isEmail(email)}
                    arrow
                    block
                  >
                    {exporting ? "Generating PDF…" : "Download Summary"}
                  </Button>
                  <Button
                    color={ButtonColor.REVERSED}
                    onClick={handleStartNewDecision}
                    type="button"
                  >
                    Start New Decision
                  </Button>
                </div>

                {exportError && (
                  <ErrorMessage
                    className={styles.SubmissionError}
                    id="submission-error"
                    contactSupport
                  >
                    {exportError}
                  </ErrorMessage>
                )}
              </div>
            </form>
          </WrapperCard>
        </div>
      </Wrapper>

      {/* This was removed from the MVP, but preserved in a non-bloating way so
          that it can be resurrected for V2 if desired. Be sure to reenable the
          styles in Resolve.module.scss too */}
      {/*<ResolveVillain />*/}
    </>
  );
}

export default Resolve;
