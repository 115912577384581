import { forwardRef, Ref, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";

import { generateId } from "utils/generateId";

import Heading, { HeadingLevel } from "components/Heading/Heading";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

import styles from "./Fieldset.module.scss";

export enum FieldsetColor {
  DEFAULT = "defaultColor",
  REVERSED = "reversedColor",
}

export enum FieldsetLabelStyle {
  HIDDEN = "hidden",
  DEFAULT = "default",
  SMALL = "small",
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref: Ref<HTMLInputElement>;
}

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  ref: Ref<HTMLTextAreaElement>;
  style: React.CSSProperties & { "--min-rows": number; "--max-rows": number };
}

interface FieldsetProps {
  label: string;
  labelStyle?: FieldsetLabelStyle;
  type?: "textarea" | InputProps["type"];
  color?: FieldsetColor;
  error?: string;
  forceMaxLength?: boolean;
  minRows?: number;
  maxRows?: number;
}

// any is validated later to ensure that it's the correct ref type
const Fieldset = forwardRef<any, FieldsetProps & (InputProps | TextareaProps)>(
  (
    {
      className,
      type,
      label,
      labelStyle = FieldsetLabelStyle.DEFAULT,
      color = FieldsetColor.DEFAULT,
      maxLength,
      error,
      forceMaxLength,
      id: _id,
      minRows: textareaMinRows = 4,
      maxRows: textareaMaxRows = 7,
      ...props
    },
    ref
  ): JSX.Element => {
    const { value } = props;

    const localInputRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || localInputRef || null;

    const localTextareaRef = useRef<HTMLTextAreaElement>(null);
    const textareaRef = ref || localTextareaRef;

    const id: string = useRef(_id || generateId()).current;
    const errorId = `error-${id}`;
    const valueLength = value?.toString().length || 0;

    const errorMessage: string = useMemo(() => {
      if (error) return error;
      if (maxLength && valueLength > maxLength) {
        return `Maximum ${maxLength} characters`;
      }
      return "";
    }, [error, valueLength, maxLength]);

    const fieldsetInputProps = {
      className: clsx(
        styles.Input,
        styles[`Input-${type}`],
        errorMessage && styles["Input-hasError"]
      ),
      maxLength: forceMaxLength ? maxLength : undefined,
      id,
      "aria-invalid": !!errorMessage,
      "aria-describedby": errorMessage ? errorId : undefined,
      "aria-label":
        labelStyle === FieldsetLabelStyle.HIDDEN ? label : undefined,
      ...props,
    };

    useEffect(() => {
      if (type === "textarea" && textareaRef) {
        const textareaEl =
          typeof textareaRef === "object" && textareaRef.current;
        if (!textareaEl) return;
        // Resizes the textarea to allow it to only take up the space it needs
        textareaEl.style.height = "";
        textareaEl.style.height = `${textareaEl.scrollHeight + 2}px`;
      }
    }, [textareaRef, type, value]);

    const showCounter =
      type === "textarea" &&
      labelStyle !== FieldsetLabelStyle.HIDDEN &&
      maxLength;

    return (
      <label
        className={clsx(
          className,
          styles.Fieldset,
          styles[`Fieldset-${color}`],
          styles[`Fieldset-${labelStyle}`]
        )}
      >
        {labelStyle !== FieldsetLabelStyle.HIDDEN && (
          <Heading
            className={styles.Label}
            level={
              labelStyle === FieldsetLabelStyle.SMALL
                ? HeadingLevel.H5
                : HeadingLevel.H4
            }
            tag="span"
          >
            {label}
            {showCounter && (
              <span className={clsx("tabular-nums", styles.LabelCounter)}>
                {valueLength}/{maxLength}
              </span>
            )}
          </Heading>
        )}
        <div className={styles.InputAndError}>
          <div className={styles.InputWrapper}>
            {type === "textarea" ? (
              <textarea
                ref={textareaRef}
                {...(fieldsetInputProps as TextareaProps)}
                style={
                  {
                    "--min-rows": textareaMinRows,
                    "--max-rows": textareaMaxRows,
                    ...props.style,
                  } as TextareaProps["style"]
                }
              />
            ) : (
              <input
                ref={inputRef}
                type={type}
                {...(fieldsetInputProps as InputProps)}
              />
            )}
            <span className={styles.FocusRing} aria-hidden="true" />
          </div>
          {errorMessage && (
            <ErrorMessage className={styles.Error} id={errorId}>
              {errorMessage}
            </ErrorMessage>
          )}
        </div>
      </label>
    );
  }
);

Fieldset.displayName = "Fieldset";

export default Fieldset;
