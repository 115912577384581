import { forwardRef, useCallback, useMemo } from "react";
import { generatePath, useHistory, Link } from "react-router-dom";
import clsx from "clsx";

import { Option } from "schema";

import routes from "constants/routes";
import { OptionsAnalyticsEvent } from "constants/options";

import { useUser } from "contexts/UserContext/UserContext";

import AnalyticsService from "services/AnalyticsService";

import { CardHeader } from "components/Card/Card";
import Heading, { HeadingLevel } from "components/Heading/Heading";
import StateIndicator, {
  State,
} from "components/StateIndicator/StateIndicator";

import { ReactComponent as IconArrowRight } from "assets/icons/16-arrow-right.svg";

import styles from "./OptionHeader.module.scss";

export enum OptionNavigationMethod {
  TAB = "tab",
  PREV = "pagination-previous",
  NEXT = "pagination-next",
  PROMPT = "prompt-next",
}

interface OptionNavLinkProps {
  className?: string;
  children?: React.ReactNode;
  optionNumber: number;
  method: OptionNavigationMethod;
}

function OptionNavLink({
  children,
  method,
  optionNumber,
  ...props
}: OptionNavLinkProps): JSX.Element {
  const history = useHistory();

  const { optionStep } = useUser();

  const optionPath = generatePath(routes.OPTION_URL, { index: optionNumber });

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      history.push(optionPath);
      AnalyticsService.track(OptionsAnalyticsEvent.NAVIGATE, {
        method,
        optionStep,
      });
    },
    [history, optionStep, method, optionPath]
  );
  return (
    <Link to={optionPath} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
}

const OptionHeader = forwardRef<HTMLDivElement, { optionId: Option["id"] }>(
  ({ optionId }, ref) => {
    const { user, options, optionStep } = useUser();

    const currentIndex = user?.optionsOrder.indexOf(optionId);
    const optionsLength = options?.length;

    const pagination = useMemo(() => {
      if (!optionsLength || currentIndex === undefined || currentIndex < 0)
        return;

      let previousIndex = currentIndex - 1;
      if (previousIndex < 0) {
        previousIndex = optionsLength - 1;
      }

      let nextIndex = currentIndex + 1;
      if (nextIndex >= optionsLength) {
        nextIndex = 0;
      }

      return {
        previous: previousIndex + 1,
        next: nextIndex + 1,
      };
    }, [optionsLength, currentIndex]) as
      | { previous: number; next: number }
      | undefined;

    if (!optionStep || !pagination) return null;

    return (
      <CardHeader className={styles.Header} ref={ref}>
        <ul className={styles.Nav}>
          <li className={clsx(styles.NavItem, styles["NavItem-pagination"])}>
            <OptionNavLink
              className={clsx(
                styles.NavPagination,
                styles["NavPagination-previous"]
              )}
              optionNumber={pagination.previous}
              method={OptionNavigationMethod.PREV}
            >
              <IconArrowRight role="presentation" />
              <span className="sr-only">Previous Option</span>
            </OptionNavLink>
          </li>
          {options?.map((option, index) => (
            <li key={option.id} className={styles.NavItem}>
              <OptionNavLink
                className={styles.NavLink}
                aria-selected={optionId === option.id}
                optionNumber={index + 1}
                method={OptionNavigationMethod.TAB}
              >
                <StateIndicator
                  state={option[optionStep] ? State.COMPLETED : State.ACTIVE}
                />
                <Heading
                  className={styles.NavLabel}
                  level={HeadingLevel.H4}
                  aria-label={`Option ${index + 1}`}
                >
                  Opt
                  <span className={styles.NavLabelHideShort}>ion</span>{" "}
                  <span className="tabular-nums">{index + 1}</span>
                </Heading>
              </OptionNavLink>
            </li>
          ))}
          <li className={clsx(styles.NavItem, styles["NavItem-pagination"])}>
            <OptionNavLink
              className={styles.NavPagination}
              optionNumber={pagination.next}
              method={OptionNavigationMethod.NEXT}
            >
              <IconArrowRight role="presentation" />
              <span className="sr-only">Next Option</span>
            </OptionNavLink>
          </li>
        </ul>
      </CardHeader>
    );
  }
);

OptionHeader.displayName = "OptionHeader";

export default OptionHeader;
