import { useCallback, useState } from "react";
import clsx from "clsx";

import {
  Mitigation as MitigationInterface,
  MitigationConsequence as MitigationConsequenceInterface,
  MitigationConsequenceType,
  Option,
} from "schema";

import {
  MAX_MITIGATIONS,
  MitigationAnalyticsEvent,
  MitigationConsequenceLabel,
  MitigationsLabel,
  MITIGATION_CONSEQUENCE_MAX_LENGTH,
  DELETE_MITIGATION_CONFIRMATION,
  MITIGATION_MAX_LENGTH,
} from "constants/mitigations";
import { OptionLabel } from "constants/options";

import { formatLongText } from "utils/formatLongText";

import AnalyticsService from "services/AnalyticsService";

import { useUser } from "contexts/UserContext/UserContext";
import {
  useAddMitigation,
  useDeleteMitigation,
  useEditMitigation,
} from "contexts/UserContext/useMitigation";

import Button, { ButtonColor, ButtonSize } from "components/Button/Button";
import CreationForm from "components/CreationForm/CreationForm";
import Fieldset, { FieldsetLabelStyle } from "components/Fieldset/Fieldset";
import Heading, { HeadingLevel } from "components/Heading/Heading";
import Item from "components/Item/Item";

import styles from "./Mitigation.module.scss";

interface MitigationConsequenceProps {
  type: MitigationConsequenceType;
  consequences: MitigationConsequenceInterface;
}

function MitigationConsequence({
  type,
  consequences,
}: MitigationConsequenceProps): JSX.Element {
  const { headingPlural, textPlural } = MitigationConsequenceLabel[type];
  return (
    <div>
      <Heading level={HeadingLevel.H5}>{headingPlural}</Heading>
      {consequences ? (
        <p className="pre-wrap">{formatLongText(consequences)}</p>
      ) : (
        <p className={styles.Empty}>No {textPlural} listed</p>
      )}
    </div>
  );
}

interface MitigationConsequencesGridProps {
  className?: string;
  upsides: MitigationInterface["upsides"];
  downsides: MitigationInterface["downsides"];
}

export function MitigationConsequencesGrid({
  className,
  upsides = "",
  downsides = "",
}: MitigationConsequencesGridProps): JSX.Element {
  return (
    <div className={clsx(className, styles.MitigationConsequences)}>
      <MitigationConsequence
        type={MitigationConsequenceType.UPSIDES}
        consequences={upsides}
      />
      <MitigationConsequence
        type={MitigationConsequenceType.DOWNSIDES}
        consequences={downsides}
      />
    </div>
  );
}

interface MitigationItemProps {
  mitigation: MitigationInterface;
  optionId: Option["id"];
  index: number;
}

function MitigationItem({
  mitigation,
  optionId,
  index,
}: MitigationItemProps): JSX.Element {
  const { id, text, upsides = "", downsides = "" } = mitigation;

  const isInitState = mitigation.upsides === undefined;

  const editMitigation = useEditMitigation();
  const deleteMitigation = useDeleteMitigation();

  const [editing, setEditing] = useState(isInitState);
  const [newText, setNewText] = useState(text);
  const [newUpsides, setNewUpsides] = useState(upsides);
  const [newDownsides, setNewDownsides] = useState(downsides);

  const changed =
    newText !== text || newUpsides !== upsides || newDownsides !== downsides;

  const handleEdit = useCallback(
    (e) => {
      e.preventDefault();

      const newTextTrim = newText.trim();
      const newUpsidesTrim = newUpsides.trim();
      const newDownsidesTrim = newDownsides.trim();

      editMitigation(id, newTextTrim, newUpsidesTrim, newDownsidesTrim);
      AnalyticsService.track(MitigationAnalyticsEvent.EDIT, {
        length: newTextTrim.length,
        upsidesLength: newUpsidesTrim.length,
        downsidesLength: newDownsidesTrim.length,
        changed,
      });

      setEditing(false);
    },
    [editMitigation, id, changed, newText, newUpsides, newDownsides]
  );

  const handleDelete = useCallback(() => {
    if (!window.confirm(DELETE_MITIGATION_CONFIRMATION)) return;

    deleteMitigation(id, optionId);
    AnalyticsService.track(MitigationAnalyticsEvent.DELETE, {});
  }, [optionId, deleteMitigation, id]);

  if (editing) {
    return (
      <Item className={clsx(styles.Mitigation, styles["Mitigation-editing"])}>
        <form className={styles.EditForm} onSubmit={handleEdit}>
          <Fieldset
            className={styles.EditFormText}
            label={`${MitigationsLabel.heading} ${index + 1}`}
            placeholder={`Enter a ${MitigationsLabel.text} for this ${OptionLabel.text}`}
            maxLength={MITIGATION_MAX_LENGTH}
            value={newText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewText(e.target.value)
            }
          />
          <Fieldset
            className={styles.EditFormConsequence}
            type="textarea"
            label={
              MitigationConsequenceLabel[MitigationConsequenceType.UPSIDES]
                .headingPlural
            }
            labelStyle={FieldsetLabelStyle.SMALL}
            placeholder={
              MitigationConsequenceLabel[MitigationConsequenceType.UPSIDES]
                .placeholder
            }
            maxLength={MITIGATION_CONSEQUENCE_MAX_LENGTH}
            value={newUpsides}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setNewUpsides(e.target.value)
            }
            minRows={3}
            maxRows={3}
          />
          <Fieldset
            className={styles.EditFormConsequence}
            type="textarea"
            label={
              MitigationConsequenceLabel[MitigationConsequenceType.DOWNSIDES]
                .headingPlural
            }
            labelStyle={FieldsetLabelStyle.SMALL}
            placeholder={
              MitigationConsequenceLabel[MitigationConsequenceType.DOWNSIDES]
                .placeholder
            }
            maxLength={MITIGATION_CONSEQUENCE_MAX_LENGTH}
            value={newDownsides}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setNewDownsides(e.target.value)
            }
            minRows={3}
            maxRows={3}
          />
          <div className={styles.EditFormButtons}>
            <Button
              className={styles.EditFormSave}
              color={ButtonColor.REVERSED}
              disabled={
                !newText ||
                newText.length > MITIGATION_MAX_LENGTH ||
                newUpsides.length > MITIGATION_CONSEQUENCE_MAX_LENGTH ||
                newDownsides.length > MITIGATION_CONSEQUENCE_MAX_LENGTH
              }
            >
              {changed || isInitState ? "Save" : "Cancel"}
            </Button>
            <Button
              color={ButtonColor.NEGATIVE}
              onClick={handleDelete}
              type="button"
              aria-label={`Delete ${MitigationsLabel.heading} ${index}`}
              title={`Delete ${MitigationsLabel.heading} ${index}`}
            >
              Delete
            </Button>
          </div>
        </form>
      </Item>
    );
  }

  return (
    <Item className={styles.Mitigation}>
      <p className={styles.MitigationText}>{text}</p>
      <MitigationConsequencesGrid upsides={upsides} downsides={downsides} />
      <Button
        className={styles.MitigationEdit}
        size={ButtonSize.SMALL}
        onClick={() => setEditing(true)}
        color={ButtonColor.REVERSED}
      >
        Edit<span className="sr-only"> {MitigationsLabel.heading}</span>
      </Button>
    </Item>
  );
}

interface MitigationProps {
  option: Option;
}

function Mitigation({ option }: MitigationProps): JSX.Element {
  const { user } = useUser();
  const optionId = option.id;

  const addMitigation = useAddMitigation();

  const handleAdd = useCallback(
    (newText: MitigationInterface["text"]) => {
      addMitigation(optionId, newText);

      AnalyticsService.track(MitigationAnalyticsEvent.CREATE, {
        length: newText.length,
      });
    },
    [optionId, addMitigation]
  );

  const mitigations: MitigationInterface[] = user
    ? option.mitigations.map((id) => user.mitigations[id])
    : [];

  return (
    <>
      {mitigations.map((mitigation, index) => (
        <MitigationItem
          key={mitigation.id}
          mitigation={mitigation}
          index={index}
          optionId={optionId}
        />
      ))}
      {mitigations.length < MAX_MITIGATIONS && (
        <CreationForm
          className={styles.CreationForm}
          label="Add new mitigation"
          labelStyle={FieldsetLabelStyle.HIDDEN}
          buttonLabel="Add Mitigation"
          placeholder="Add a mitigation for the downsides"
          maxLength={MITIGATION_MAX_LENGTH}
          onAdd={handleAdd}
        />
      )}
    </>
  );
}

export default Mitigation;
