import {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

import {
  Step,
  User,
  Option,
  OptionStep,
  Outcome,
  APP_SCHEMA_VERSION,
} from "schema";

import routes from "constants/routes";
import { MIN_OPTIONS } from "constants/options";
import { MIN_OUTCOMES } from "constants/outcomes";

import { generateId } from "utils/generateId";

import AnalyticsService from "services/AnalyticsService";

export const STEPS = [
  Step.INTRO,
  Step.OUTCOMES,
  Step.CREATE_OPTIONS,
  Step.OPTION,
  Step.RESOLVE,
];

export const OPTION_STEPS = [
  OptionStep.CONSEQUENCES,
  OptionStep.EVALUATE,
  OptionStep.MITIGATE,
];

export const STEP_URLS: { [step: string]: string } = {
  [Step.INTRO]: routes.INTRO_URL,
  [Step.OUTCOMES]: routes.OUTCOMES_URL,
  [Step.CREATE_OPTIONS]: routes.CREATE_OPTIONS_URL,
  [Step.OPTION]: routes.OPTION_URL,
  [Step.RESOLVE]: routes.RESOLVE_URL,
};

const LOCAL_USER = "user";

type ContextProps = {
  user?: User;
  setUser: Function;
};

const UserContext = createContext<Partial<ContextProps>>({});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export const UserContextProvider = ({
  children,
}: UserContextProviderProps): JSX.Element => {
  const identifiedRef = useRef(false);
  const [user, setUser] = useState(() => {
    const localValue = localStorage.getItem(LOCAL_USER);
    // JSON can't parse undefined, so we have to handle it as a string
    return localValue && localValue !== "undefined"
      ? JSON.parse(localValue)
      : undefined;
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_USER, JSON.stringify(user));
    // The identify call is deduped to reduce the number of calls to the
    // analytics service
    if (user && !identifiedRef.current) {
      identifiedRef.current = true;
      AnalyticsService.identify(user.id, {});
    } else if (!user) {
      identifiedRef.current = false;
      AnalyticsService.reset();
    }
  }, [user]);

  const value = useMemo(() => {
    const value: ContextProps = {
      user,
      setUser,
    };

    return value;
  }, [user, setUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUser() {
  const { user } = useContext(UserContext);

  const options: Option[] | undefined = user?.optionsOrder.map(
    (optionId) => user.options[optionId]
  );

  const outcomes: Outcome[] | undefined = user?.outcomesOrder.map(
    (outcomeId) => user.outcomes[outcomeId]
  );

  function computeUserStep(
    user: User | undefined,
    options: Option[] | undefined
  ): Step {
    if (!user) return Step.INTRO;
    if (user.outcomesOrder.length < MIN_OUTCOMES) return Step.OUTCOMES;
    if (user.optionsOrder.length < MIN_OPTIONS) return Step.CREATE_OPTIONS;
    // Options has a conditional chain here, but if user doesn't exist this
    // will always be an array
    if (options?.some((option) => !option.mitigate)) return Step.OPTION;
    return Step.RESOLVE;
  }

  const step: Step = computeUserStep(user, options);

  return {
    user,
    step,
    optionStep: user?.optionStep,
    options,
    outcomes,
  };
}

export function useSchema(): {
  mismatch: boolean;
  user: number | undefined;
  app: number;
} {
  const { user } = useContext(UserContext);

  const mismatch = !!user && user.schemaVersion !== APP_SCHEMA_VERSION;

  return {
    mismatch,
    user: user?.schemaVersion,
    app: APP_SCHEMA_VERSION,
  };
}

export function useSetUser() {
  const { setUser } = useContext(UserContext);

  return setUser;
}

export function useResetUser() {
  return useCallback(() => {
    localStorage.removeItem(LOCAL_USER);
  }, []);
}

export function usePatchUser() {
  const setUser = useSetUser();

  return useCallback(
    (patch: Partial<User>) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        return {
          ...oldUser,
          ...patch,
        };
      });
    },
    [setUser]
  );
}

export function useSetOptionStep() {
  const patchUser = usePatchUser();

  return useCallback(
    (newOptionStep: OptionStep) => {
      if (!patchUser) return;

      patchUser({
        optionStep: newOptionStep,
      });
    },
    [patchUser]
  );
}

export function useRedirectStep(routeStep: Step) {
  const { step } = useUser();

  if (STEPS.indexOf(routeStep) <= STEPS.indexOf(step)) return;

  // Return the URL for the current step if their routeStep isn't something
  // they have access to yet
  return STEP_URLS[step];
}

function generateEmptyUser(
  id: User["id"],
  decisionId: User["decisionId"]
): User {
  return {
    id,
    schemaVersion: APP_SCHEMA_VERSION,
    decisionId,
    step: Step.OUTCOMES,
    optionStep: OptionStep.CONSEQUENCES,
    outcomes: {},
    outcomesOrder: [],
    options: {},
    optionsOrder: [],
    consequences: {},
    mitigations: {},
    helperTooltips: {},
  };
}

export function useCreateUser() {
  const { setUser } = useContext(UserContext);

  return useCallback(() => {
    const newUserId = generateId();
    const newDecisionId = generateId();

    setUser && setUser(generateEmptyUser(newUserId, newDecisionId));
  }, [setUser]);
}

export function useStartNewDecision() {
  const { user, setUser } = useContext(UserContext);

  const userId = user?.id;

  return useCallback(() => {
    // In the unlikely scenario where a user is resetting themselves, but there
    // is no user id, we generate a new one as if we were setting them up for
    // the first time
    const newUserId = userId || generateId();
    const newDecisionId = generateId();

    setUser && setUser(generateEmptyUser(newUserId, newDecisionId));
  }, [userId, setUser]);
}
