/**
 * @param  {string} text
 * @returns {string} The original string, but with 2+ consecutive line-breaks
 *  replaced with a single line-break, returns replaced with line-breaks, and
 *  whitespace trimmed from the start and end.
 */
export function formatLongText(text: string) {
  if (typeof text !== "string")
    throw new Error(`Invalid arguments: "text" (${text}) must be a string`);
  return text.replace(/[\r\n]+/g, "\n").trim();
}
