import { useCallback, useRef } from "react";
import { generatePath, Redirect } from "react-router-dom";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { Option, Step, User } from "schema";

import routes from "constants/routes";
import {
  MAX_OPTIONS,
  MIN_OPTIONS,
  OptionLabel,
  OptionsAnalyticsEvent,
  OptionStepLabel,
  OPTION_MAX_LENGTH,
} from "constants/options";

import useSetInitialScroll from "hooks/useSetInitialScroll";

import AnalyticsService from "services/AnalyticsService";

import { useRedirectStep, useUser } from "contexts/UserContext/UserContext";
import {
  useAddOption,
  useSetOptionsOrder,
} from "contexts/UserContext/useOption";

import Button, { ButtonSize } from "components/Button/Button";
import CreationForm from "components/CreationForm/CreationForm";

import CreateOptionsItem from "./CreateOptionsItem";
import Wrapper, { WrapperCard } from "../Wrapper/Wrapper";
import WrapperCardFooter from "../Wrapper/WrapperCardFooter";

export const CREATE_OPTIONS_ID = "options";

function Options(): JSX.Element {
  useSetInitialScroll();

  const cardRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const { user, options = [], optionStep } = useUser();

  const redirectUserStep = useRedirectStep(Step.CREATE_OPTIONS);
  const addOption = useAddOption();
  const setOptionsOrder = useSetOptionsOrder();

  const optionsOrder = user?.optionsOrder;

  const handleAdd = useCallback(
    (newText: Option["text"]) => {
      addOption(newText);
      AnalyticsService.track(OptionsAnalyticsEvent.CREATE, {
        length: newText.length,
      });
    },
    [addOption]
  );

  const handleReorder = useCallback(
    (result: DropResult) => {
      if (!optionsOrder) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination?.index;

      // Ignore the rorder if there is no destination or if there is no change
      if (destinationIndex === undefined || sourceIndex === destinationIndex) {
        return;
      }

      const newOrder: User["optionsOrder"] = [...optionsOrder];
      const [idToMove] = newOrder.splice(sourceIndex, 1);
      newOrder.splice(destinationIndex, 0, idToMove);

      setOptionsOrder(newOrder);

      AnalyticsService.track(OptionsAnalyticsEvent.REORDER, {});
    },
    [optionsOrder, setOptionsOrder]
  );

  if (redirectUserStep) {
    return <Redirect to={redirectUserStep} />;
  }

  return (
    <Wrapper
      heading="Know Your Options"
      stepNumber={2}
      copy={
        <>
          <p>
            Capture all of your options to achieve the outcomes you want,
            including those that initially may sound far-fetched, or options
            that you don’t like. The more options you have, the more confident
            you’ll be in making tough&nbsp;decisions.
          </p>
          <p>
            Come up with at least three options. Remember:{" "}
            <strong>
              One option is no choice. Two options is a dilemma. Three options
              represents true&nbsp;choice.
            </strong>
          </p>
        </>
      }
    >
      <WrapperCard
        ref={cardRef}
        id={CREATE_OPTIONS_ID}
        footer={
          <WrapperCardFooter
            ref={footerRef}
            helperText={
              <span>Add at least {MIN_OPTIONS} Options to progress</span>
            }
            button={
              <Button
                size={ButtonSize.LARGE}
                block
                arrow
                to={generatePath(routes.OPTION_URL, { index: 1 })}
              >
                {optionStep ? OptionStepLabel[optionStep].breadcrumb : ""}
              </Button>
            }
            disabled={!options?.length || options.length < MIN_OPTIONS}
          />
        }
        footerRef={footerRef}
      >
        <DragDropContext onDragEnd={handleReorder}>
          <Droppable droppableId="options">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {options.map((option: any, index: number) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <CreateOptionsItem
                        ref={provided.innerRef}
                        id={option.id}
                        number={index + 1}
                        text={option.text}
                        dragging={snapshot.isDragging}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {options.length < MAX_OPTIONS && (
          <CreationForm
            onAdd={handleAdd}
            label={`${OptionLabel.heading} ${options.length + 1}`}
            buttonLabel={`Add ${OptionLabel.heading}`}
            placeholder={`What are your ${OptionLabel.textPlural}?`}
            maxLength={OPTION_MAX_LENGTH}
            marginTop={options.length > 0}
          />
        )}
      </WrapperCard>
    </Wrapper>
  );
}

export default Options;
