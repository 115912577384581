import { Link } from "react-router-dom";
import clsx from "clsx";

import { ReactComponent as IconArrowRight } from "assets/icons/16-arrow-right.svg";

import styles from "./Button.module.scss";

export enum ButtonColor {
  DEFAULT = "default",
  REVERSED = "reversed",
  INACTIVE = "inactive",
  COMPLETE = "complete",
  NEGATIVE = "negative",
}

export enum ButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

interface SharedButtonProps {
  className?: string;
  children?: React.ReactNode;
  color?: ButtonColor;
  size?: ButtonSize;
  reversed?: boolean;
  disabled?: boolean;
  arrow?: boolean;
  block?: boolean;
}

export type LinkElementButtonProps = {
  to: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement> &
  SharedButtonProps;

export type AnchorElementButtonProps =
  {} & React.AnchorHTMLAttributes<HTMLAnchorElement> & SharedButtonProps;

export type ButtonElementButtonProps =
  {} & React.ButtonHTMLAttributes<HTMLButtonElement> & SharedButtonProps;

type ButtonProps =
  | LinkElementButtonProps
  | AnchorElementButtonProps
  | ButtonElementButtonProps;

function Button({
  children,
  className,
  color = ButtonColor.DEFAULT,
  size = ButtonSize.MEDIUM,
  arrow,
  block,
  ...props
}: ButtonProps): JSX.Element {
  const buttonClassName = clsx(
    className,
    styles.Button,
    styles[`Button-${color}`],
    styles[`Button-${size}`],
    block && styles["Button-block"]
  );
  const buttonChildren = (
    <>
      <span className={styles.Children}>{children}</span>
      {arrow && <IconArrowRight className={styles.Arrow} role="presentation" />}
    </>
  );

  if ((props as LinkElementButtonProps).to) {
    return (
      <Link className={buttonClassName} {...(props as LinkElementButtonProps)}>
        {buttonChildren}
      </Link>
    );
  }

  if ((props as AnchorElementButtonProps).href) {
    return (
      <a className={buttonClassName} {...(props as AnchorElementButtonProps)}>
        {buttonChildren}
      </a>
    );
  }

  return (
    <button
      className={buttonClassName}
      {...(props as ButtonElementButtonProps)}
    >
      {buttonChildren}
    </button>
  );
}

export default Button;
