import { forwardRef } from "react";
import clsx from "clsx";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";

import { ReactComponent as DragHandle } from "./drag-handle.svg";

import styles from "./Item.module.scss";

export type DraggableItemProps = {
  className?: string;
  children?: React.ReactNode;
  dragging: boolean;
} & (DraggableProvidedDragHandleProps | DraggableProvidedDraggableProps);

export const DraggableItem = forwardRef<HTMLDivElement, DraggableItemProps>(
  ({ className, children, dragging, ...props }, ref): JSX.Element => {
    const draggable = dragging !== undefined;

    return (
      <div
        className={clsx(
          className,
          styles.Item,
          draggable && styles["Item-draggable"],
          dragging && styles["Item-dragging"]
        )}
        ref={ref}
        {...props}
      >
        {children}

        {draggable && (
          <DragHandle
            className={styles.ItemHandle}
            role="presentation"
            title="Drag to reorder"
          />
        )}
      </div>
    );
  }
);

DraggableItem.displayName = "DraggableItem";

interface ItemProps {
  className?: string;
  children?: React.ReactNode;
}

function Item({ className, children, ...props }: ItemProps): JSX.Element {
  return (
    <div className={clsx(className, styles.Item)} {...props}>
      {children}
    </div>
  );
}

export default Item;
