import clsx from "clsx";

import {
  useDismissHelperTooltip,
  useHelperTooltipDismissed,
} from "contexts/UserContext/useHelperTooltip";

import styles from "./HelperTooltip.module.scss";

interface HelperTooltipProps {
  className?: string;
  tooltipId: string;
  dismiss: boolean;
}

function HelperTooltip({
  className,
  tooltipId,
  dismiss,
  ...props
}: HelperTooltipProps): JSX.Element | null {
  const dismissHelperTooltip = useDismissHelperTooltip();
  const isDismissed = useHelperTooltipDismissed(tooltipId);

  if (isDismissed) return null;

  return (
    <span
      className={clsx(
        className,
        styles.Beacon,
        dismiss && styles["Beacon-hidden"]
      )}
      aria-hidden="true"
      onTransitionEnd={() => dismissHelperTooltip(tooltipId)}
      {...props}
    />
  );
}

export default HelperTooltip;
