import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FallbackProps } from "react-error-boundary";

import AnalyticsService from "services/AnalyticsService";

import { useResetUser } from "contexts/UserContext/UserContext";

import Button, { ButtonSize } from "components/Button/Button";
import Card from "components/Card/Card";
import Container from "components/Container/Container";
import { ContactSupportError } from "components/ErrorMessage/ErrorMessage";
import Hero from "components/Hero/Hero";
import Heading, { HeadingLevel } from "components/Heading/Heading";

import styles from "./AppError.module.scss";

interface AppErrorProps {
  heading?: string;
  text?: string;
  resetUser?: boolean;
  showError?: boolean;
  onReset?: Function;
}

function AppError({
  error,
  heading = "Oops, something went wrong!",
  text = "An error occured, please try again.",
  onReset,
  showError = true,
}: Partial<FallbackProps> & AppErrorProps) {
  const { pathname } = useLocation();

  const resetUser = useResetUser();

  useEffect(() => {
    AnalyticsService.track("App Error Boundary triggered", {
      error: error?.toString(),
      pathname,
    });
  }, [error, pathname]);

  const handleReset = useCallback(() => {
    if (onReset) {
      onReset();
      return;
    }

    resetUser();
    window.location.reload();
  }, [onReset, resetUser]);

  return (
    <main>
      <Hero verticallyCenter>
        <Container>
          <Heading
            className={styles.HeroHeading}
            level={HeadingLevel.H1}
            marginTop={false}
          >
            {heading}
          </Heading>
        </Container>
      </Hero>
      <Card className="text">
        {text && <p>{text}</p>}
        <p>
          <ContactSupportError />
        </p>
        <Button
          className={styles.ResetButton}
          size={ButtonSize.LARGE}
          onClick={handleReset}
          block
        >
          Try again
        </Button>

        {error && showError && (
          <details className={styles.Error}>
            <summary>
              <Heading
                className={styles.ErrorTitle}
                level={HeadingLevel.H4}
                tag="span"
              >
                Error Message
              </Heading>
            </summary>
            <pre>{error.message}</pre>
          </details>
        )}
      </Card>
    </main>
  );
}

export default AppError;
