import clsx from "clsx";

import { externalUrls } from "constants/routes";

import { ReactComponent as IconError } from "assets/icons/16-error.svg";

import styles from "./ErrorMessage.module.scss";

export function ContactSupportError() {
  return (
    <>
      If this issue persists{" "}
      <a href={externalUrls.CONTACT} target="_blank" rel="noreferrer">
        contact support
      </a>
      .
    </>
  );
}

interface ErrorProps {
  className?: string;
  children?: React.ReactNode;
  id: string;
  contactSupport?: boolean;
}

function ErrorMessage({
  className,
  children,
  contactSupport,
  ...props
}: ErrorProps): JSX.Element {
  return (
    <span className={clsx(className, styles.Error)} {...props}>
      <span className={styles.IconWrapper}>
        <IconError role="presentation" />
      </span>
      <span>
        {children}
        {contactSupport && (
          <>
            {" "}
            <ContactSupportError />
          </>
        )}
      </span>
    </span>
  );
}

export default ErrorMessage;
