import { forwardRef, useCallback, useState } from "react";

import { Outcome } from "schema";

import { OPTION_MAX_LENGTH } from "constants/options";
import {
  DELETE_OUTCOME_CONFIRMATION,
  OutcomesAnalyticsEvent,
  OutcomesLabel,
} from "constants/outcomes";

import AnalyticsService from "services/AnalyticsService";

import {
  useDeleteOutcome,
  useEditOutcome,
} from "contexts/UserContext/useOutcome";

import { DraggableItem, DraggableItemProps } from "components/Item/Item";
import ItemHeading from "components/Item/ItemHeading";
import EditingForm from "components/EditingForm/EditingForm";

interface OutcomeItemProps {
  id: Outcome["id"];
  text: Outcome["text"];
  number: number;
}

type DraggableOutcomeItemProps = OutcomeItemProps & DraggableItemProps;

const OutcomeItem = forwardRef<HTMLDivElement, DraggableOutcomeItemProps>(
  ({ id, text, number, ...props }, ref): JSX.Element => {
    const [editing, setEditing] = useState(false);

    const deleteOutcome = useDeleteOutcome();
    const editOutcome = useEditOutcome();

    const handleEdit = useCallback(
      (newText: Outcome["text"]) => {
        const changed = text !== newText;
        if (changed) {
          editOutcome(id, newText);
        }
        setEditing(false);
        AnalyticsService.track(OutcomesAnalyticsEvent.EDIT, {
          changed,
          length: newText.length,
        });
      },
      [id, text, editOutcome]
    );

    const handleDelete = useCallback(() => {
      if (!window.confirm(DELETE_OUTCOME_CONFIRMATION)) return;

      deleteOutcome(id);
      AnalyticsService.track(OutcomesAnalyticsEvent.DELETE, {});
    }, [id, deleteOutcome]);

    return (
      <DraggableItem ref={ref} {...props}>
        {editing ? (
          <EditingForm
            maxLength={OPTION_MAX_LENGTH}
            label={`${OutcomesLabel.heading} ${number}`}
            onEdit={handleEdit}
            onDelete={handleDelete}
            initialValue={text}
          />
        ) : (
          <div>
            <ItemHeading
              label={OutcomesLabel.heading}
              number={number}
              onEditClick={() => setEditing(true)}
            >
              {text}
            </ItemHeading>
          </div>
        )}
      </DraggableItem>
    );
  }
);

OutcomeItem.displayName = "OutcomeName";

export default OutcomeItem;
