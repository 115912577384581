import { useCallback } from "react";

import {
  MITIGATION_CONSEQUENCE_MAX_LENGTH,
  MITIGATION_MAX_LENGTH,
} from "constants/mitigations";

import { Option, User, Mitigation } from "schema";

import { deepClone } from "utils/deepClone";
import { generateId } from "utils/generateId";

import { useSetUser } from "./UserContext";

export function useAddMitigation() {
  const setUser = useSetUser();

  return useCallback(
    (optionId: Option["id"], newText: Mitigation["text"]) => {
      if (newText.length === 0 || newText.length > MITIGATION_MAX_LENGTH)
        throw new Error(
          `Text (${newText}) must be between 1 and ${MITIGATION_MAX_LENGTH} ` +
            "characters"
        );
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.options[optionId])
          throw new Error(`Option (${optionId}) not found`);

        const newUser = deepClone(oldUser);

        const id = generateId();

        const newMitigation: Mitigation = {
          id,
          text: newText,
        };

        newUser.mitigations[id] = newMitigation;
        newUser.options[optionId].mitigations.push(id);

        return newUser;
      });
    },
    [setUser]
  );
}

export function useEditMitigation() {
  const setUser = useSetUser();

  return useCallback(
    (
      mitigationId: Mitigation["id"],
      newText: Mitigation["text"],
      newUpsides: Mitigation["upsides"] = "",
      newDownsides: Mitigation["downsides"] = ""
    ) => {
      if (newText.length === 0 || newText.length > MITIGATION_MAX_LENGTH)
        throw new Error(
          `Text (${newText}) must be between 1 and ${MITIGATION_MAX_LENGTH} ` +
            "characters"
        );
      if (newUpsides.length > MITIGATION_CONSEQUENCE_MAX_LENGTH)
        throw new Error(
          `Upsides (${newUpsides}) must be less than or equal to ` +
            MITIGATION_CONSEQUENCE_MAX_LENGTH
        );
      if (newDownsides.length > MITIGATION_CONSEQUENCE_MAX_LENGTH)
        throw new Error(
          `Downsides (${newDownsides}) must be less than or equal to ` +
            MITIGATION_CONSEQUENCE_MAX_LENGTH
        );

      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.mitigations[mitigationId])
          throw new Error(`Mitigation (${mitigationId}) not found`);

        const newUser = deepClone(oldUser);

        newUser.mitigations[mitigationId].text = newText;
        newUser.mitigations[mitigationId].upsides = newUpsides;
        newUser.mitigations[mitigationId].downsides = newDownsides;

        return newUser;
      });
    },
    [setUser]
  );
}

export function useDeleteMitigation() {
  const setUser = useSetUser();

  return useCallback(
    (mitigationId: Mitigation["id"], optionId: Option["id"]) => {
      if (!setUser) return;

      setUser((oldUser: User): User => {
        if (!oldUser.mitigations[mitigationId])
          throw new Error(`Mitigation (${mitigationId}) not found`);

        const newUser = deepClone(oldUser);

        delete newUser.mitigations[mitigationId];

        const option = newUser.options[optionId];
        option.mitigations = option.mitigations.filter(
          (id: string) => id !== mitigationId
        );

        return newUser;
      });
    },
    [setUser]
  );
}
