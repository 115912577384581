import clsx from "clsx";

import styles from "./List.module.scss";

export enum ListType {
  ORDERED = "ol",
  UNORDERED = "ul",
}

interface ListProps {
  className?: string;
  children?: React.ReactNode;
  type?: ListType;
  tag?: ListType;
}

function List({
  className,
  children,
  type = ListType.UNORDERED,
  // Allows for semantic lists that look visually like the other type
  tag: ListTag = type,
  ...props
}: ListProps): JSX.Element {
  return (
    <ListTag
      className={clsx(className, styles.List, styles[`List-${type}`])}
      {...props}
    >
      {children}
    </ListTag>
  );
}

export default List;
