import { forwardRef, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import clsx from "clsx";

import { OptionStep, Step } from "schema";

import routes from "constants/routes";
import { MIN_OUTCOMES } from "constants/outcomes";

import { OptionStepLabel, MIN_OPTIONS } from "constants/options";

import { useUser, OPTION_STEPS } from "contexts/UserContext/UserContext";

import { AnchorElementButtonProps } from "components/Button/Button";
import { CardFooter } from "components/Card/Card";
import StateIndicator, {
  State,
} from "components/StateIndicator/StateIndicator";

import styles from "./WrapperCardFooter.module.scss";

interface BreadcrumbProps {
  children?: React.ReactNode;
  state: State;
}

function Breadcrumb({
  children,
  state,
  ...props
}: BreadcrumbProps): JSX.Element {
  return (
    <li
      className={clsx(
        styles.BreadcrumbsItem,
        styles[`BreadcrumbsItem-${state}`]
      )}
      {...props}
    >
      <StateIndicator
        className={styles.BreadcrumbsStateIndicator}
        state={state}
      />
      <span
        className={clsx(
          styles.BreadcrumbsTrack,
          styles[`BreadcrumbsTrack-${state}`]
        )}
        aria-hidden="true"
      />
      <span className={styles.BreadcrumbsLabel}>{children}</span>
    </li>
  );
}

function Breadcrumbs(): JSX.Element | null {
  const { user, step, optionStep, options = [], outcomes = [] } = useUser();

  const matchesCreateOptionsRoute = useRouteMatch(routes.CREATE_OPTIONS_URL);
  const matchesOptionRoute = useRouteMatch(routes.OPTION_URL);

  let routeStep = Step.OUTCOMES;
  if (matchesCreateOptionsRoute) {
    routeStep = Step.CREATE_OPTIONS;
  } else if (matchesOptionRoute) {
    routeStep = Step.OPTION;
  }

  const chosenOptionId = user?.chosenOptionId;

  const calculateBreadcrumbState = useCallback(
    (breadcrumbStep: Step | OptionStep): State => {
      // This switch statement handles each breadcrumbStep independently as the
      // conditions for working out the state are pretty unique per step. There
      // is some repetition that could be removed, but not at the expense of
      // the simplicity of a series of if conditions.
      switch (breadcrumbStep) {
        case Step.OUTCOMES:
          if (routeStep === Step.OUTCOMES && options.length === 0) {
            return outcomes.length >= MIN_OUTCOMES
              ? State.COMPLETED
              : State.ACTIVE;
          }
          return State.PAST;

        case Step.CREATE_OPTIONS:
          if (
            routeStep === Step.OPTION ||
            options.some((option) => option[OptionStep.CONSEQUENCES])
          ) {
            return State.PAST;
          }
          if (routeStep === Step.OUTCOMES) {
            if (!outcomes.length) {
              return State.FUTURE;
            }
            return options.length >= MIN_OPTIONS ? State.PAST : State.ACTIVE;
          }
          if (routeStep === Step.CREATE_OPTIONS) {
            if (options.length < MIN_OPTIONS) {
              return State.ACTIVE;
            }
            if (
              options.some((option) => option[OptionStep.CONSEQUENCES]) ||
              optionStep !== OptionStep.CONSEQUENCES
            ) {
              return State.PAST;
            }
            return State.COMPLETED;
          }
          return State.FUTURE;

        case OptionStep.CONSEQUENCES:
          if (step === Step.RESOLVE) {
            return State.PAST;
          }
          if (step !== Step.OPTION) {
            return State.FUTURE;
          }
          if (optionStep !== OptionStep.CONSEQUENCES) {
            return State.PAST;
          }
          if (
            routeStep === Step.OPTION &&
            options.every((option) => option[OptionStep.CONSEQUENCES])
          ) {
            return State.COMPLETED;
          }
          return State.ACTIVE;

        case OptionStep.EVALUATE:
          if (step === Step.RESOLVE) {
            return State.PAST;
          }
          if (step !== Step.OPTION || optionStep === OptionStep.CONSEQUENCES) {
            return State.FUTURE;
          }
          if (optionStep === OptionStep.MITIGATE) {
            return State.PAST;
          }
          if (
            routeStep === Step.OPTION &&
            options.every((option) => option[OptionStep.EVALUATE])
          ) {
            return State.COMPLETED;
          }
          return State.ACTIVE;

        case OptionStep.MITIGATE:
          if (step === Step.RESOLVE) {
            return chosenOptionId ? State.PAST : State.COMPLETED;
          }
          if (step !== Step.OPTION || optionStep !== OptionStep.MITIGATE) {
            return State.FUTURE;
          }
          if (
            routeStep === Step.OPTION &&
            options.every((option) => option[OptionStep.MITIGATE])
          ) {
            return State.COMPLETED;
          }
          return State.ACTIVE;

        case Step.RESOLVE:
          if (step === Step.RESOLVE) {
            return State.ACTIVE;
          }
          return State.FUTURE;

        default:
          return State.FUTURE;
      }
    },
    [step, routeStep, optionStep, options, outcomes, chosenOptionId]
  );

  if (!optionStep) return null;

  return (
    <ul className={styles.Breadcrumbs}>
      <Breadcrumb state={calculateBreadcrumbState(Step.OUTCOMES)}>
        Outcomes
      </Breadcrumb>
      <Breadcrumb state={calculateBreadcrumbState(Step.CREATE_OPTIONS)}>
        Options
      </Breadcrumb>
      {OPTION_STEPS.map((optionStep) => {
        return (
          <Breadcrumb
            key={optionStep}
            state={calculateBreadcrumbState(optionStep)}
          >
            {OptionStepLabel[optionStep].breadcrumb}
          </Breadcrumb>
        );
      })}
      <Breadcrumb state={calculateBreadcrumbState(Step.RESOLVE)}>
        Resolve
      </Breadcrumb>
    </ul>
  );
}

interface WrapperCardFooterProps {
  helperText: React.ReactNode;
  button: React.ReactElement<AnchorElementButtonProps>;
  disabled: boolean;
}

const WrapperCardFooter = forwardRef<HTMLDivElement, WrapperCardFooterProps>(
  ({ helperText, button, disabled }, ref): JSX.Element => {
    return (
      <CardFooter className={styles.Wrapper} ref={ref}>
        <Breadcrumbs />

        <div className={styles.Progress}>
          <p aria-hidden={!disabled}>{helperText}</p>
          {/* The button isn't disabled to avoid a janky switch when the div
              fades in, but the CSS adds visibility hidden to ensure that the
              button behaves as if it were disabled */}
          <div aria-hidden={disabled}>{button}</div>
        </div>
      </CardFooter>
    );
  }
);

WrapperCardFooter.displayName = "WrapperCardFooter";

export default WrapperCardFooter;
