import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  generatePath,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import {
  Option as OptionInterface,
  OptionStep,
  Step,
  ConsequenceType,
  Outcome,
} from "schema";

import routes from "constants/routes";
import {
  DELETE_OPTION_CONFIRMATION,
  OptionProgressButtonLabel,
  OptionsAnalyticsEvent,
  OptionStepLabel,
  OptionLabel,
} from "constants/options";
import { OutcomesLabel } from "constants/outcomes";
import { MitigationsLabel } from "constants/mitigations";
import { ConsequenceLabels } from "constants/consequences";

import { generateId } from "utils/generateId";

import useSetInitialScroll, {
  scrollToElement,
} from "hooks/useSetInitialScroll";

import AnalyticsService from "services/AnalyticsService";

import {
  useUser,
  useRedirectStep,
  useSetOptionStep,
  OPTION_STEPS,
} from "contexts/UserContext/UserContext";
import {
  useDeleteOption,
  useLinkOutcome,
  useToggleOptionState,
} from "contexts/UserContext/useOption";

import Heading, { HeadingLevel } from "components/Heading/Heading";
import HelperTooltip from "components/HelperTooltip/HelperTooltip";
import Button, { ButtonColor, ButtonSize } from "components/Button/Button";
import ToggleButton from "components/ToggleButton/ToggleButton";
import ItemHeading from "components/Item/ItemHeading";
import StateCheckbox from "components/StateCheckbox/StateCheckbox";

import OptionHeader, { OptionNavigationMethod } from "./OptionHeader";
import Consequences from "./Consequences";
import Mitigation from "./Mitigation";
import { CREATE_OPTIONS_ID } from "../CreateOptions/CreateOptions";
import Wrapper, { WrapperCard } from "../Wrapper/Wrapper";
import WrapperCardFooter from "../Wrapper/WrapperCardFooter";

import styles from "./Option.module.scss";

export const EDIT_OPTION_ID = "options";

const OptionStepCopy: { [key: string]: React.ReactNode } = {
  [OptionStep.CONSEQUENCES]: (
    <>
      <p>
        Now look at each of your options. For each one, brainstorm the potential
        upsides and downsides. What could you gain by each option? What could it
        cost&nbsp;you?
      </p>
      <p>
        Also for each option, capture which outcome(s) are most affected and
        write a brief summary based on what you know at this stage of
        the&nbsp;process.
      </p>
      <p>
        Once you are done, mark the option as assessed, or discard any options
        that you can rule out based on what you’ve&nbsp;learned.
      </p>
    </>
  ),
  [OptionStep.EVALUATE]: (
    <>
      <p>
        Review each of your remaining options as well as the upsides and
        downsides of&nbsp;each.
      </p>
      <p>
        Then, for each upside and downside, capture the potential impact on a
        scale of 1–10 if this upside or downside were to occur. Then, capture
        the probability that the upside or downside would likely occur on a
        scale of&nbsp;1–10.
      </p>
      <p>
        When you're finished, write a short summary of the option now that
        you've evaluated it on a deeper level. Don’t overthink it and stick with
        the process! It’s important to capture where you are at the end of
        each&nbsp;step.
      </p>
    </>
  ),
  [OptionStep.MITIGATE]: (
    <>
      <p>
        For each of your remaining options, it’s time to review the downside
        consequences. For each one, brainstorm alternative ways to eliminate or
        measurably reduce those downsides. What are some new options or
        strategies to mitigate the current downsides and meet your original
        outcomes, perhaps in a better&nbsp;way?
      </p>
      <p>
        Be sure to capture some of the biggest upsides and downsides of any new
        options you come up with as well, to make sure that you complete the
        thought process for these alternatives. Keep going through each of the
        steps… you’re almost&nbsp;done!
      </p>
    </>
  ),
};

function Option(): JSX.Element | null {
  const history = useHistory();
  const { pathname } = useLocation();

  const { index } = useParams<Record<string, string | undefined>>();

  const cardRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const relatedOutcomesLabelRef = useRef(generateId());
  const previousOptionStepRef = useRef("");

  useSetInitialScroll();

  const { options, optionStep, outcomes } = useUser();

  const setOptionStep = useSetOptionStep();
  const redirectUserStep = useRedirectStep(Step.OPTION);
  const deleteOption = useDeleteOption();
  const toggleOptionState = useToggleOptionState();
  const linkOutcome = useLinkOutcome();

  const option: OptionInterface | null | undefined = useMemo(() => {
    if (!options || index === undefined) return;

    return options[parseInt(index) - 1] || null;
  }, [index, options]);

  const optionId = option?.id || "";
  const optionCompleted = option && optionStep && option[optionStep];

  // This layout effect observes the change in option step and the option index
  // and scrolls accordingly.
  useLayoutEffect(() => {
    if (!optionStep) return;

    // If previousOptionStep has been set it isn't the first render (which is
    // handled by the useSetInitialScroll hook above) so we can safely handle
    // the scroll position change
    if (previousOptionStepRef.current) {
      if (previousOptionStepRef.current !== optionStep) {
        // When the optionStep changes, scroll the user to the very top so they
        // can read the new content
        window.scrollTo(0, 0);
      } else {
        cardRef.current && scrollToElement(cardRef.current, false);
      }
    }

    previousOptionStepRef.current = optionStep;
  }, [
    optionStep,
    // optionId isn't used in the layout effect, but is imperative for
    // responding to the UI changes
    optionId,
  ]);

  const handleLinkOutcome = useCallback(
    (id: Outcome["id"], isLinked: boolean) => {
      if (!optionId) return;
      linkOutcome(optionId, id, isLinked);
      if (isLinked) {
        AnalyticsService.track(OptionsAnalyticsEvent.LINK_OUTCOME, {});
      } else {
        AnalyticsService.track(OptionsAnalyticsEvent.UNLINK_OUTCOME, {});
      }
    },
    [optionId, linkOutcome]
  );

  const handleToggle = useCallback(() => {
    if (!optionStep || !optionId) return;

    toggleOptionState(optionId, optionStep);
    AnalyticsService.track(OptionsAnalyticsEvent.TOGGLE_STATE, {
      state: !optionCompleted,
      optionStep,
    });
  }, [optionId, optionCompleted, optionStep, toggleOptionState]);

  const handleDiscard = useCallback(() => {
    if (!optionId || !window.confirm(DELETE_OPTION_CONFIRMATION)) return;

    deleteOption(optionId);
    AnalyticsService.track(OptionsAnalyticsEvent.DELETE, {
      location: pathname,
    });
  }, [optionId, deleteOption, pathname]);

  const handleGoToNext = useCallback((): void => {
    if (optionStep === OptionStep.MITIGATE) {
      history.push(routes.RESOLVE_URL);
      return;
    }

    const nextOptionStep =
      optionStep === OptionStep.CONSEQUENCES
        ? OptionStep.EVALUATE
        : OptionStep.MITIGATE;

    setOptionStep(nextOptionStep);
    history.push(generatePath(routes.OPTION_URL, { index: 1 }));
    AnalyticsService.track(OptionsAnalyticsEvent.PROGRESS_STEP, {
      optionStep: nextOptionStep,
    });
  }, [history, optionStep, setOptionStep]);

  const handleGoToEdit = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      const href = e.currentTarget.getAttribute("href");
      if (!href) return;

      history.push(href);
      AnalyticsService.track(OptionsAnalyticsEvent.EDIT_OPTIONS_URL, {
        location: pathname,
      });
    },
    [history, pathname]
  );

  const incompleteOptionNumbers: number[] = useMemo(() => {
    const incompleteOptionNumbers: number[] = [];

    if (options && optionStep) {
      options.forEach((option, index) => {
        if (option[optionStep]) return;
        incompleteOptionNumbers.push(index + 1);
      });
    }
    return incompleteOptionNumbers;
  }, [options, optionStep]);

  const [nextDisplayedOptNum, setNextDisplayedOptNum] = useState(1);
  const nextIncompleteOptNum: number | undefined = useMemo(() => {
    if (!index) return;

    // If the current option is incomplete or all options are complete, the
    // button should not show
    if (!optionCompleted || !incompleteOptionNumbers.length) return;

    return (
      incompleteOptionNumbers.find((number) => number > parseInt(index)) ||
      incompleteOptionNumbers[0]
    );
  }, [index, optionCompleted, incompleteOptionNumbers]);

  // Because we have a button that fades in and out, we need to keep the previous
  // value in a state because it is needed while the button fades out
  useEffect(() => {
    if (!nextIncompleteOptNum) return;
    setNextDisplayedOptNum(nextIncompleteOptNum);
  }, [nextIncompleteOptNum]);

  const handlePromptNavigation = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const href = e.currentTarget.getAttribute("href");
      if (!href) return;

      history.push(href);
      AnalyticsService.track(OptionsAnalyticsEvent.NAVIGATE, {
        method: OptionNavigationMethod.PROMPT,
        optionStep,
      });
    },
    [history, optionStep]
  );

  if (redirectUserStep) {
    return <Redirect to={redirectUserStep} />;
  }

  if (option === null) {
    return <Redirect to={generatePath(routes.OPTION_URL, { index: 1 })} />;
  }

  if (!option || !optionId || !optionStep || !index) return null;

  return (
    <Wrapper
      heading={OptionStepLabel[optionStep].heading}
      copy={OptionStepCopy[optionStep]}
      stepNumber={OPTION_STEPS.indexOf(optionStep) + 3}
    >
      <WrapperCard
        ref={cardRef}
        id={EDIT_OPTION_ID}
        className={styles.Card}
        header={<OptionHeader ref={headerRef} optionId={optionId} />}
        headerRef={headerRef}
        footer={
          <WrapperCardFooter
            ref={footerRef}
            button={
              <Button
                size={ButtonSize.LARGE}
                arrow
                block
                onClick={handleGoToNext}
              >
                {OptionProgressButtonLabel[optionStep]}
              </Button>
            }
            helperText={
              <span>
                Mark all {OptionLabel.headingPlural} as{" "}
                {OptionStepLabel[optionStep].completedVerb} to&nbsp;progress
              </span>
            }
            disabled={!!incompleteOptionNumbers.length}
          />
        }
        footerRef={footerRef}
      >
        <ItemHeading
          number={parseInt(index)}
          label={OptionLabel.heading}
          onEditClick={handleGoToEdit}
          to={`${routes.CREATE_OPTIONS_URL}#${CREATE_OPTIONS_ID}`}
        >
          {option.text}
        </ItemHeading>

        <Consequences
          consequenceType={ConsequenceType.UPSIDES}
          option={option}
          optionNumber={parseInt(index)}
          showHelperTooltip
        />

        <hr />

        <Consequences
          consequenceType={ConsequenceType.DOWNSIDES}
          option={option}
          optionNumber={parseInt(index)}
          showHelperTooltip={option.upsides.length === 0}
        />

        {optionStep === OptionStep.MITIGATE && (
          <>
            <p className={styles.MitigationHelper}>
              How could you {MitigationsLabel.verb} the{" "}
              {ConsequenceLabels.downsides.textPlural} of this&nbsp;
              {OptionLabel.text}?
              <HelperTooltip
                tooltipId="mitigate"
                dismiss={option.mitigations.length > 0}
              />
            </p>
            <Mitigation option={option} key={`mitigation-${optionId}`} />
          </>
        )}

        <hr />

        <Heading className={styles.OutcomesHeading} level={HeadingLevel.H3}>
          {OutcomesLabel.headingPlural} Affected
          <HelperTooltip
            tooltipId={"assess-outcomes"}
            dismiss={
              option.linkedOutcomes.length > 0 ||
              optionStep !== OptionStep.CONSEQUENCES
            }
          />
        </Heading>
        <p
          className={styles.OutcomesHelper}
          id={relatedOutcomesLabelRef.current}
        >
          Select the outcomes that are most affected by this option and write a
          brief summary for this option based on what you know so&nbsp;far.
        </p>

        <fieldset
          className={styles.RelatedOutcomes}
          key={`related-${optionId}`}
          aria-labelledby={relatedOutcomesLabelRef.current}
        >
          {outcomes?.map((outcome, outcomeIndex) => {
            const checked = option.linkedOutcomes.includes(outcome.id);
            return (
              <label key={outcome.id}>
                <span className={styles.RelatedOutcomesCheckbox}>
                  <StateCheckbox checked={checked} />
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => handleLinkOutcome(outcome.id, !checked)}
                  />
                  <span className={styles.RelatedOutcomesFocusRing} />
                </span>
                <span className={styles.RelatedOutcomesText}>
                  <Heading tag="strong" level={HeadingLevel.H4}>
                    Outcome{" "}
                    <span className="tabular-nums">{outcomeIndex + 1}</span>
                    {/* This adds a slightly wider space without needing extra
                        CSS to control it */}
                    &ensp;
                  </Heading>
                  <span>{outcome.text}</span>
                </span>
              </label>
            );
          })}
        </fieldset>

        <div className={styles.Buttons} key={`buttons-${optionId}`}>
          <div className={styles.ButtonsPrimary}>
            <ToggleButton
              className={styles.ButtonsToggle}
              checked={!!optionCompleted}
              onClick={handleToggle}
              uncheckedLabel={
                <>
                  Mark{" "}
                  <span className={styles.ButtonsToggleHideOnSmallScreens}>
                    {OptionLabel.heading}{" "}
                    <span className="tabular-nums">{index}</span>
                  </span>{" "}
                  as {OptionStepLabel[optionStep].completedVerb}
                </>
              }
              checkedLabel={
                <>
                  <span className={styles.ButtonsToggleHideOnSmallScreens}>
                    {OptionLabel.heading}{" "}
                    <span className="tabular-nums">{index}</span>
                  </span>{" "}
                  {OptionStepLabel[optionStep].completedVerb}
                </>
              }
            />
            <div
              className={styles.ButtonsNext}
              aria-hidden={nextIncompleteOptNum === undefined}
            >
              <Button
                color={ButtonColor.REVERSED}
                arrow
                onClick={handlePromptNavigation}
                to={generatePath(routes.OPTION_URL, {
                  index: nextDisplayedOptNum,
                })}
              >
                {OptionLabel.heading}{" "}
                <span className="tabular-nums">{nextDisplayedOptNum}</span>
              </Button>
            </div>
          </div>

          <Button color={ButtonColor.NEGATIVE} onClick={handleDiscard}>
            Discard
            <span className={styles.ButtonsToggleHideOnSmallScreens}>
              {" "}
              {OptionLabel.heading}
            </span>
          </Button>
        </div>

        {process.env.NODE_ENV === "development" && false && (
          <details>
            <summary>Option Debug</summary>
            <pre>{option && JSON.stringify(option, null, 2)}</pre>
          </details>
        )}
      </WrapperCard>
    </Wrapper>
  );
}

export default Option;
