import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import AnalyticsService from "services/AnalyticsService";

import {
  usePatchUser,
  UserContextProvider,
  useResetUser,
  useSchema,
  useUser,
} from "contexts/UserContext/UserContext";

import routes from "constants/routes";

import AppError from "containers/AppError/AppError";
import Intro from "containers/Intro/Intro";
import Outcomes from "containers/Steps/Outcomes/Outcomes";
import CreateOptions from "containers/Steps/CreateOptions/CreateOptions";
import Option from "containers/Steps/Option/Option";
import Resolve from "containers/Steps/Resolve/Resolve";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

function LocationObserver() {
  const { pathname } = useLocation();

  useEffect(() => {
    AnalyticsService.page(pathname);
  }, [pathname]);

  return null;
}

function SchemaObserver() {
  // Rather than using a complicated semver versioning here, there is just the
  // concept of breaking changes – when the schema changes in a way that
  // would cause app errors of any kind for users, the version is bumped by 1.
  // This is a solution for the MVP, but if this is revisited, it should be
  // replaced with proper version migration functionality.
  const { mismatch, user, app } = useSchema();

  if (!mismatch) return null;

  throw new Error(`User schema (v${user}) does not match app schema (v${app})`);
}

function TemporaryResetButton() {
  const { step } = useUser();
  const resetUser = useResetUser();
  const patchUser = usePatchUser();

  return (
    <div
      style={{
        position: "absolute",
        top: "0.5rem",
        right: "0.5rem",
        display: "flex",
        gap: "0.25rem",
      }}
    >
      Reset:
      <button
        onClick={() => {
          resetUser();
          window.location.href = routes.INTRO_URL;
        }}
      >
        User ({step})
      </button>
      <button
        onClick={() => {
          patchUser({
            helperTooltips: {},
          });
        }}
      >
        Tooltips
      </button>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Header />

      <ErrorBoundary
        fallbackRender={(errorProps) => <AppError {...errorProps} />}
      >
        <Switch>
          <Route exact path={routes.INTRO_URL}>
            <Intro />
          </Route>
          <Route exact path={routes.OUTCOMES_URL}>
            <Outcomes />
          </Route>
          <Route exact path={routes.CREATE_OPTIONS_URL}>
            <CreateOptions />
          </Route>
          <Route exact path={routes.OPTION_URL}>
            <Option />
          </Route>
          <Route exact path={routes.RESOLVE_URL}>
            <Resolve />
          </Route>
          <Route>
            <AppError
              heading="404 – Page not found"
              text={""}
              error={new Error("Page not found")}
              showError={false}
              onReset={() => {
                window.location.href = routes.INTRO_URL;
              }}
            />
          </Route>
        </Switch>

        <Footer />

        <LocationObserver />
        <SchemaObserver />

        {process.env.REACT_APP_NETLIFY_CONTEXT !== "production" && (
          <TemporaryResetButton />
        )}
      </ErrorBoundary>
    </Router>
  );
}

function AppWithContext() {
  return (
    <UserContextProvider>
      <App />
    </UserContextProvider>
  );
}

export default AppWithContext;
