import clsx from "clsx";

import { ReactComponent as IconCheck } from "assets/icons/16-check.svg";

import styles from "./StateCheckbox.module.scss";

interface StateCheckboxProps {
  className?: string;
  checked: boolean;
}

function StateCheckbox({
  className,
  checked,
  ...props
}: StateCheckboxProps): JSX.Element {
  return (
    <span
      className={clsx(
        className,
        styles.StateCheckbox,
        checked && styles[`StateCheckbox-checked`]
      )}
      {...props}
    >
      <IconCheck role="presentation" />
    </span>
  );
}

export default StateCheckbox;
